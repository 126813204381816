<template>
  <div class="bg">
    <section>
      <div v-if="showPremiumLogin">

        <div id="logoWrapper">
          <span>BETA</span>
          <img src="../assets/gfx/greenmbp.png" id="logo" alt="Marketbit.pro"/>
        </div>
        <h1>

        </h1>
        <h2>
          Don't Miss Out on Market Opportunities.
        </h2>


        <TrialEnded :user="user" v-if="trialEnded"/>

        <div v-if="!trialEnded" id="loginSection">

          <div v-if="isResetPassword" class="formContainer">
            <h2 style="margin: 0 0 1rem; font-weight: 600">Password reset</h2>
            <form class="login" style="width: 100%" @submit.prevent="setNewPassword()">
              <input type="password" v-model="newPassword" placeholder="Your new password"/>


              <MBPButton class="action mbp bold full large" :disabled="waiting"
                         label="Reset Marketbit.pro password"/>

              <p class="mbpLink" @click="showForgotPassword = false; isResetPassword = false;">Back to login</p>
            </form>
          </div>

          <div v-else class="formContainer">
            <div v-if="!validationRequiredNotice" style="width: 100%;">
              <div v-if="!showForgotPassword && !showNewUser" class="formContainer">
                <form class="login" @submit.prevent="login()">
                  <input type="email" v-model="email" placeholder="Email"/>
                  <input type="password" v-model="password" placeholder="Password"/>
                  <div v-if="invalid">
                    <p class="invalid">{{ loginError }}</p>
                  </div>

                  <MBPButton class="action mbp bold full large" :disabled="!password || !email || waiting"
                             label="Login to Marketbit.pro"/>

                  <p class="mbpLink" @click="showForgotPassword = true">Forgot your password?</p>

                </form>

                <p class="center">OR</p>
                <MBPButton class="action mbp bold full large google" :icon="'ri-google-fill'" @click="signInWithGoogle"
                           label="Sign in with Google"/>

                <div v-if="googleLoginFailed" style="margin-top: 1rem">
                  <p class="invalid">{{ loginError }}</p>
                </div>

                <p id="terms">
                  By using the service you agree to the <a href="https://www.marketbit.pro/terms-of-use"
                                                           target="_blank">terms of use</a>.
                </p>


                <div class="div"/>
                <h2 style="margin-bottom: 1rem;">New user?</h2>
                <MBPButton class="action mbp bold full large" @click="showNewUser = true;"
                           label="Start your free trial now"/>
              </div>

              <div v-if="showForgotPassword" class="formContainer">
                <form class="login" @submit.prevent="forgotPassword()" v-if="!forgotPasswordWasSent">
                  <input type="email" v-model="forgotPasswordEmail" placeholder="Email"/>
                  <div v-if="invalid">
                    <p class="invalid">Email or password are wrong</p>
                  </div>

                  <div v-if="forgotPasswordError">
                    <p class="invalid">Your email wasn't found. Please sign up or contact us.</p>
                  </div>

                  <MBPButton class="action mbp bold full large" :disabled="!forgotPasswordEmail || waiting"
                             label="Reset Marketbit.pro password"/>

                  <p class="mbpLink" @click="showForgotPassword = false">Back to login</p>

                </form>
                <div v-else>
                  <p style="color: white; line-height: 26px">A reset password link was sent to your inbox
                    <b>{{ forgotPasswordEmail }}</b><br/>
                    Please follow the instructions<br>(and check the Spam inbox just in case...)</p>
                </div>
              </div>

              <div v-if="showNewUser" class="formContainer">
                <div class="login">
                  <form
                      @submit.prevent="signupUser({firstName: newUserFirstName, lastName: newUserLastName, email: newUserEmail, password: newUserPassword, auth: 'mbp'})">

                    <input type="text" v-model="newUserFirstName" placeholder="First name"/>
                    <input type="text" v-model="newUserLastName" placeholder="Last name"/>
                    <input type="email" v-model="newUserEmail" placeholder="Email"/>
                    <input type="password" v-model="newUserPassword" placeholder="Password"/>

                    <div v-if="newUserInvalid">
                      <p class="invalid">{{ loginError }}</p>
                    </div>

                    <p id="approve">
                      <label class="approveCheckbox">
                        <input type="checkbox" v-model="termsApproved" @change="approveTermsToggle">
                      </label>
                      I have read and approve the <a href="https://www.marketbit.pro/terms-of-use" target="_blank">terms
                      of use</a>.
                    </p>
                    <MBPButton class="action mbp bold full large"
                               v-if="termsApproved"
                               :disabled="!newUserEmail || !newUserPassword || waiting"
                               label="Signup to Marketbit.pro"/>
                  </form>

                  <p class="center" v-if="termsApproved" style="margin-top: 1rem;">OR</p>
                  <MBPButton v-if="termsApproved" class="action mbp bold full large google" :icon="'ri-google-fill'"
                             @click="signInWithGoogle(true)"
                             label="Sign up with Google"/>


                  <p class="mbpLink" @click="showNewUser = false">Back to login</p>

                </div>

              </div>
            </div>
            <div style="width: 100%" v-else>
              <h2 style="margin: 0 0 1rem; font-weight: 600">Thank you for signing up!</h2>
              <p class="center">We've sent you an activation link to <b
                  style="color: var(--mbpGreen);">{{ newUserEmail }}</b>.<br/>Make sure you click on it to get started.
                If
                you can't find it check your spam inbox as well.</p>
            </div>
          </div>

          <p class="center" style="margin-top: 1rem;">
            Contact us <a href="mailto:hello@marketbit.pro" class="mbpLink">hello@marketbit.pro</a>
          </p>
        </div>
      </div>
    </section>
  </div>

</template>

<script>

import UserService from '../services/user';
import TrialEnded from "./settingsView/TrialEnded";
import {auth, googleProvider} from '@/services/firebase'; // Import the Firebase configuration
import {signInWithPopup} from 'firebase/auth';
import MBPButton from "../components/ui/controls/MBPButton";
import MBPCheckbox from "../components/ui/controls/MBPCheckbox";

export default {
  name: "PremiumLockView",
  components: {MBPCheckbox, MBPButton, TrialEnded},
  props: ['trialEnded', 'showPremiumLogin', 'user'],
  data() {
    return {
      termsApproved: false,
      invalid: false,
      loginError: null,
      password: null,
      email: null,
      waiting: false,
      showNewUser: false,
      forgotPasswordEmail: null,
      showForgotPassword: false,
      forgotPasswordWasSent: false,
      newPassword: null,
      forgotPasswordError: false,
      googleLoginFailed: false,
      newUserFirstName: null,
      newUserLastName: null,
      newUserEmail: null,
      newUserPassword: null,
      newUserInvalid: false,
      validationRequiredNotice: false,
      isActivation: false,
      isResetPassword: false,
      resetCode: null,
      affiliateCode: null
    }
  },
  async beforeMount() {

    if (location.search.includes('?code')) {
      this.isActivation = true;
      const code = location.search.replace('?code=', '');
      await this.activateUser(code);
    } else if (location.search.includes('?resetPassword')) {

      this.isResetPassword = true;
      this.resetCode = location.search.replace('?resetPassword=', '');
    } else if (location.search.includes('?afl')) {

      this.affiliateCode = location.search.replace('?afl=', '');
      UserService.tagAffiliationUser(this.affiliateCode).then().catch();
    }
  },
  methods: {
    approveTermsToggle(value) {

    },
    async activateUser(code) {
      try {
        await UserService.activateUser(code);
        location.href = '/';
        // this.$router.push('/');
        // this.$router.go(1);
      } catch (e) {

      }
    },
    async googleLogin(userDetails, newUser) {


      try {

        this.waiting = true;

        const result = await UserService.premiumGoogleLogin(userDetails, 'google', 'mbp', this.affiliateCode);
        if (result) {
          location.reload();
        }
      } catch (e) {

        console.log(e)
        this.loginError = `Something is wrong... Please try again later.`;
        this.googleLoginFailed = true;
      } finally {

        this.waiting = false;
      }
    },
    async signupUser({firstName, lastName, email, accessToken, password, auth = 'email', refClient = 'mpb'}) {

      this.newUserInvalid = false;
      this.loginError = null;

      try {
        this.newUserInvalid = false;
        await UserService.signupUser({
          firstName,
          lastName,
          token: accessToken,
          email,
          password,
          auth,
          affiliateCode: this.affiliateCode,
          refClient
        })
      } catch (e) {
        if (e.response.data.userStatus === 'EXISTS') {
          this.newUserInvalid = true;
          this.loginError = `This user already exists. Please go back to login.`
        } else if (e.response.data.userStatus === 'INVALIDATED_EMAIL') {
          this.validationRequiredNotice = true;
        }
      }
    },
    async forgotPassword() {

      this.waiting = true;
      const status = await UserService.forgotPassword(this.forgotPasswordEmail);
      if (status.status === 'SENT') {
        this.forgotPasswordWasSent = true;
      } else {
        this.forgotPasswordError = true;
      }
      this.waiting = false;
    },
    async setNewPassword() {

      this.waiting = true;
      await UserService.setNewPassword({code: this.resetCode, password: this.newPassword});
      location.href = '/';
    },
    async signInWithGoogle(newUser = false) {

      this.invalid = false;
      try {
        this.googleLoginFailed = false;
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        if (!user.emailVerified) {

          this.loginError = `${user.email} is not verified by Google.`
          this.googleLoginFailed = true;
          return;
        }

        if (newUser) {
          await this.signupUser(Object.assign({accessToken: user.accessToken}, {
            auth: 'google',
            refClient: 'mbp',
            affiliateCode: this.affiliateCode
          }))
        } else {
          await this.googleLogin(user.accessToken, newUser);
        }

      } catch (error) {

        this.loginError = `Can't login with Google`
        this.googleLoginFailed = true;
      }
    },
    async login() {

      if (!this.password && this.password.toString().trim().length === 0) {

        return false;
      }

      if (!this.email && this.email.toString().trim().length === 0) {

        return false;
      }

      this.invalid = false;
      try {

        this.waiting = true;
        const result = await UserService.premiumLogin(this.email, this.password);
        if (result) {
          location.reload();
        }
      } catch (e) {

        this.loginError = 'Email or password are wrong';
        this.invalid = true;
      } finally {

        this.waiting = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">

.bg {
  background: linear-gradient(180deg, #1e1c1d 0, #232122 100%);

}

section {
  display: flex;
  flex-direction: column;
  direction: ltr;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
  background-image: url("~@/assets/gfx/nodes.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;

  * {
    direction: ltr;
  }
}

.invalid {
  color: var(--red);
  background: var(--opacityRed);
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 1rem;
}

h1 {
  font-size: 4rem;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  color: var(--mbpGreen);
  position: relative;
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


  @media (max-width: 660px) {
    font-size: 2rem;
  }
}

h2 {
  color: #fefefe;
  font-weight: 300;
  margin: 0 auto 5rem;
}

form {
  direction: ltr;
  display: block;
  margin: 0 auto;
  width: 100%;
}

input {
  display: block;
  padding: 10px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  &:active,
  &:focus {
    border-color: #00ffaa;
    outline: none;
  }
}


#loginSection {
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media(max-width: 660px) {
    width: 90%;
  }
}

.formContainer {
  width: 100%;
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
}

.div {
  height: 1px;
  background: white;
  opacity: .5;
  margin: 1rem 0;
  width: 100%;
}

p {
  font-weight: 500;
  font-size: 18px;
}

.center {
  color: white;
  margin: 0 0 1rem;
  padding: 0;
}

.google {
  background: var(--lightYellowOpacity) !important;
  color: var(--lightYellow) !important;
}

.approveCheckbox {
  display: inline-flex;
  user-select: none;
}

#approve {
  user-select: none;
  color: white;

  a {
    color: var(--mbpGreen);
  }
}

#terms {
  color: white;

  a {
    color: var(--mbpGreen);
  }
}

#logoWrapper {
  position: relative;
  width: fit-content;
  margin: 0 auto;

  @media(max-width: 660px) {
    width: 100%;
  }

  span {
    position: absolute;
    right: -35px;
    bottom: 0;
    transform: translateX(50%) translateY(-200%);
    display: block;
    font-weight: 600;
    font-size: 18px;
    background: #008c20;
    width: fit-content;
    border-radius: 8px;
    padding: 2px 5px;
    color: #1e1c1d;
    @media(max-width: 660px) {
      right: 0;
      font-size: 14px;
      bottom: 17%;
      transform: translateX(50%);
    }
  }
}

#logo {
  width: 360px;
  @media(max-width: 660px) {
    width: 80%;
  }
}
</style>
