<template>
  <section v-if="trade">

    <div class="symbolData">

      <div class="box">
        <div class="symbolAndLogo">
          <symbol-link :symbol="trade.symbol"/>
          <symbol-logo :symbol="trade.symbol"/>
          <h1>{{ trade.companyDetails?.exchange ? trade.companyDetails.exchange + ':' : '' }}{{ trade.symbol }}</h1>
        </div>
        <h2>
          {{ trade.companyDetails.name }}
          <symbol-label
              :pre-market-quote="trade.tradingData.preMarket"
              :post-market-quote="trade.tradingData.postMarket"
              :symbol="trade.tradingData" :price-only="true" :always-on="true"/>
        </h2>
        <p>
          <span v-html="sectorIndustry"/>
        </p>
      </div>

      <div class="box" style="margin-top: 1rem;">
        <span style="font-size: 20px; font-weight: 500"><span
            v-html="dictionary.trades.sinceTradeWasPublished(trade.symbol)"/><span
            class="changeLabel emphasisText"
            :class="{up: priceChange > 0, down: priceChange < 0}">{{
            priceChange > 0 ? dictionary.trades.gained : dictionary.trades.lost
          }} {{ priceChange }}%</span></span>

        <p class="emphasisText">
          <span><i class="ri-calendar-line"/> {{ dictionary.trades.published }} @</span> <span
            class="highlight">{{ formatDate(trade.dateAdded) }} {{
            daysAgo(trade.dateAdded)
          }}</span>{{ trade.lastUpdate ? ', ' : '.' }}
          <span v-if="trade.lastUpdate">{{ dictionary.trades.updated }} @</span>
          <span class="highlight" v-if="trade.lastUpdate">{{ formatDate(trade.lastUpdate) }} {{
              daysAgo(trade.lastUpdate)
            }}</span>{{ trade.lastUpdate ? '.' : '' }}
        </p>
        <p class="emphasisText" v-if="trade.followups?.length > 0" v-html="dictionary.trades.tradeHasFollowups(trade.followups.length)">

        </p>
      </div>

    </div>

    <div class="followups">
      <div class="followup" v-for="(followup, fIdx) in trade.followups" :key="`f-${fIdx}`">

        <div class="tradeTitle" :class="{hasFollowups: trade.followups.length > 0}">
          <h1 @click="followup.hide = !followup.hide">{{ followup.title[systemLanguage || 'en'] ?? followup.title.en }}
            <i v-if="!followup.hide" class="ri-arrow-up-s-line"/>
            <i v-else class="ri-arrow-down-s-line"/>
          </h1>
          <span><i class="ri-calendar-line"/> {{ dictionary.trades.updated }} @</span> <span
            class="highlight">{{ formatDate(followup.dateAdded) }} {{
            daysAgo(followup.dateAdded)
          }}</span>
        </div>
        <div class="content tradePost" :dir="uiDirection" :class="{collapsed: followup.hide}">
          <div v-if="!followup.hide" v-html="followup.content[systemLanguage || 'en'] ?? followup.content.en">
          </div>
        </div>

      </div>
    </div>


    <div class="tradeTitle" :class="{hasFollowups: trade.followups.length > 0}">
      <h1>{{ trade.title[systemLanguage || 'en'] ?? trade.title.en }}</h1>
      <span><i class="ri-calendar-line"/> {{ dictionary.trades.published }} @</span> <span
        class="highlight">{{ formatDate(trade.dateAdded) }} {{
        daysAgo(trade.dateAdded)
      }}</span>
    </div>
    <div class="content tradePost" v-html="trade.content[systemLanguage || 'en'] ?? trade.content.en"></div>

  </section>
</template>

<script>
import {DateTime} from "luxon";

import TradesService from '../services/trades';
import SymbolLogo from "../components/symbols/symbolLogo";
import SymbolLink from "../components/symbols/symbolLink";
import SymbolLabel from "../components/symbols/symbol";
import quoteStreaming from "../composables/quoteStreaming";
import {useEquityPrices} from "../stores/equityPrices";
import ImageThumbnail from "../components/ui/imageThumbnail";

export default {
  name: "TradeView",
  components: {ImageThumbnail, SymbolLabel, SymbolLink, SymbolLogo},
  data() {
    return {
      trade: null,
      isLoading: true,
    }
  },
  setup() {

    const equityPrices = useEquityPrices();
    const {updateStream, leaveStream} = quoteStreaming();
    return {updateStream, leaveStream, equityPrices};
  },
  computed: {
    priceChange() {

      const initPrice = this.trade.priceOnInit.price;
      const currentPrice = this.equityPrices?.prices[this.trade.symbol]?.price;

      return parseFloat(((currentPrice - initPrice) / initPrice).toFixed(2)) * 100

    },
    sectorIndustry() {

      let string = [];

      if (this.trade.companyDetails?.sector) {

        string.push(this.trade.companyDetails?.sector)
      }

      if (this.trade.companyDetails?.industry) {

        string.push(this.trade.companyDetails?.industry)
      }

      if (string.length > 0) {

        return string.join('&nbsp;&bull;&nbsp;') + '<br/>'
      }

      return '';
    }
  },
  methods: {
    replacedContent(content) {
      return content.replace(/<img[^>]*src="([^"]*)"[^>]*>/g, (match, src, alt) => {
        return `<image-thumbnail :border="true" :base64="${src}"/>`;
      });
    },
    async getTradePageByTradeId() {
      this.trade = await TradesService.getTradePageById(this.$route.params.tradeId);
    },
    daysAgo(date) {

      if (!date) return '';
      const specificDate = DateTime.fromISO(date);
      const now = DateTime.now();
      const diffInDays = now.diff(specificDate, 'days').days;

      return `(${Math.round(diffInDays)}${this.dictionary.trades.daysAgo})`
    },
    formatDate(date) {

      return DateTime.fromISO(date).setLocale(this.dateFormatLocale).toFormat('DD')
    },
  },
  async created() {

    await this.getTradePageByTradeId();
    this.$nextTick(() => {

      const tradePosts = document.querySelectorAll('.tradePost');
      for (let post of tradePosts) {

        const images = post.querySelectorAll('img');

        for (let image of images) {

          const imageThumbnailWrapper = document.createElement('div');
          imageThumbnailWrapper.className = 'imageThumbnail border';

          const small = document.createElement('small');

          image.parentNode.insertBefore(imageThumbnailWrapper, image);
          imageThumbnailWrapper.appendChild(image);
          imageThumbnailWrapper.appendChild(small);

          imageThumbnailWrapper.addEventListener('click', (e) => {

            const newTab = window.open();

            newTab.document.write(`
                <html>
                    <head>
                        <title>Base64 Image</title>
                    </head>
                    <body>
                        <img src="${image.src}" alt="Marketbit.pro" style="width: 100%; height: auto;" />
                    </body>
                </html>
            `);

            newTab.document.close();
          });
        }
      }
    });
  },
}
</script>

<style scoped lang="scss">

@import "../assets/style/general";


section {
  padding: 1rem;
}

.followups {
  margin-top: 65px;
}

.tradeTitle {
  h1 {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  margin-bottom: 1rem;

  &.hasFollowups {
    cursor: pointer;
  }
}



.box {
  border-radius: 8px;
  background: var(--tableHoverColorOpacity);
  padding: 1rem;

}

:deep {
  .highlight {
    color: var(--lightYellow);
  }

  .content {
    background: var(--tableHoverColorOpacity);
    padding: 1rem;
    border-radius: 8px;
    box-sizing: border-box;
    margin-left: 2rem;
    position: relative;



    &.collapsed {
      padding: .5rem;
      background: transparent;
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      transform: translateX(-2rem);
      width: 2px;
      border-left: 5px dotted var(--tableHoverColor);
    }

    p {
      margin-top: 0;
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    h1 {
      margin-top: 0;
      padding-top: 0;
    }

    &[dir="rtl"] {
      margin-left: unset;
      margin-right: 2rem;
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateX(100%);
        width: 2px;
        border-right: 5px dotted var(--tableHoverColor);
        border-left: unset;
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.emphasisText {
  font-weight: 600;
}

.symbolData {

  h2 {
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 1.5rem;
  }

  p {
    margin: 10px 0 0 0;
    padding: 0;
  }
}

.symbolAndLogo {
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
  }
}

.changeLabel {
  padding: 2px 5px;
  border-radius: 5px;

  &.up {
    background: var(--opacityGreen);
    color: var(--green);
  }

  &.down {
    background: var(--opacityRed);
    color: var(--red);
  }
}
</style>
