<template>

  <div v-if="systemError" id="systemUpdate">
    <h1>

      <img src="./assets/gfx/logofull.png" id="logo" style="width: 230px; display: block; margin: 0 auto 1rem;"
           alt="Marketbit.pro"/>
      {{ systemLanguage === 'he' ? 'תחזוקת מערכת' : 'System maintenance' }}
    </h1>
    <p style="direction: rtl" v-if="systemLanguage === 'he'">
      השרתים שלנו נמצאים כרגע במצב תחזוקה עקב עומס חריג.<br/>
      אנו נחזור לפעילות במהירות האפשרית. אנו מתנצלים על אי הנוחות ומודים לכם על הסבלנות.
    </p>
    <p style="direction: ltr" v-else>
      Our servers are currently undergoing maintenance due to unusual traffic. <br/>We will be back up and running as
      soon as possible.
      <br/>Thank you for your patience.
    </p>
  </div>

  <div v-if="!systemError">
    <div v-if="!userPingged">
      <LockView v-if="locked"/>
      <PremiumLockView :trial-ended="trialEnded" :user="trialEndedUser" :showPremiumLogin="showPremiumLogin"
                       v-if="prmLock"/>
    </div>
    <div id="appWrapper" v-else>


      <Toast :message="appToastMessage" ref="appToastMessage"/>

      <div v-if="!acceptedTerms">
        <Terms @accepted="acceptedTermsInit()" :terms="termsContent" v-if="!acceptedTerms"/>
      </div>
      <div v-if="acceptedTerms && dictionary.navBar" :key="globalKey">

        <BetaOverNotification v-if="isProUI" :user="trialEndedUser"/>
        <OverlayUI @close="isFirstLogin = false" v-if="isFirstLogin">
          <div class="banner bottom systemLanguage">
            <FirstLoginInstructions @close="isFirstLogin = false"/>
          </div>
        </OverlayUI>
<!--        <OverlayUI v-if="isFirstLogin">-->
<!--        <OverlayUI v-if="true">-->
<!--          <div class="banner bottom systemLanguage" :dir="uiDirection">-->
<!--            <h4 style="margin-bottom: 2rem;">{{dictionary.messages.welcomeBanner}}</h4>-->
<!--            <p>{{dictionary.messages.languageBanner}}</p>-->
<!--            <div id="buttons">-->
<!--              <MBPButton class="action" @click="changeLang('en', true)" :label="dictionary.buttons.en"/>-->
<!--              <MBPButton class="action" @click="changeLang('he', true)" :label="dictionary.buttons.he"/>-->
<!--            </div>-->

<!--          <div class="" style="margin-bottom: 1rem; margin-top: 1rem; padding-top: 1rem; border-top: 1px solid var(&#45;&#45;notficationBorderColor)">-->
<!--            <p style="display: block; margin-bottom: .5rem">{{ dictionary.settingsPage.account.labels.systemTheme }}:</p>-->
<!--            <div class="row">-->
<!--              <toggle-switch-->
<!--                  :small="false" :state="systemTheme === 'darkMode'" @stateChanged="systemTheme === 'darkMode' ? systemTheme = 'lightMode' : systemTheme = 'darkMode'; themeChange(systemTheme)"-->
<!--                  :off-text="dictionary.settingsPage.account.labels.lightMode"-->
<!--                  :text="dictionary.settingsPage.account.labels.darkMode"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          </div>-->

<!--        </OverlayUI>-->

        <keep-alive>
          <SearchBar @close="showSearchBar = false" v-if="showSearchBar"/>
        </keep-alive>

        <ContactUs v-if="showContactUs" @close="showContactUs = false;" @sent="contactUsSent"/>

        <div id="contactUsSent" style="display: none;" class="hide" ref="thankYou">
          <span>{{ dictionary.messages.contactMessageSentTitle }}</span>
          <br/>
          {{ dictionary.messages.contactUsThankYou }}
        </div>

        <Transition name="slide-fade">
          <nav id="mobileNavBar" v-if="showMobileSideBar">
            <router-link to="/dailyBrief" class="navLink" @click="showMobileSideBar = false; clickEvent('home')"><i
                class="ri-home-2-line"/>
              {{ dictionary.navBar.home }}
            </router-link>
            <router-link to="/feed" class="navLink" @click="showMobileSideBar = false; clickEvent('feed news')"><i
                class="ri-newspaper-line"/>
              {{ dictionary.navBar.stocksFeed }}
            </router-link>
            <router-link to="/macro" class="navLink" @click="showMobileSideBar = false; clickEvent('macro news')"><i
                class="ri-global-line"/>
              {{ dictionary.navBar.macroFeed }}
            </router-link>
            <router-link to="/watchlist" class="navLink" v-if="!isProUI"
                         @click="showMobileSideBar = false; clickEvent('watchlist')"><i
                class="ri-folder-line"/>
              {{ dictionary.navBar.myWatchlist }}
            </router-link>
            <router-link to="/watchlistPro" class="navLink" v-if="isProUI"
                         @click="showMobileSideBar = false; clickEvent('watchlist')"><i
                class="ri-folder-line"/>
              {{ dictionary.navBar.myWatchlist }}
            </router-link>
            <router-link to="/indices" class="navLink" @click="showMobileSideBar = false; clickEvent('indices')"
                         v-if="isProUI">
              <i
                  class="ri-global-line"/>
              <span>
                {{ dictionary.navBar.indices }}
              </span>
            </router-link>
            <router-link to="/calendars" class="navLink" @click="showMobileSideBar = false; clickEvent('calendars')"><i
                class="ri-calendar-line"/>
              {{ dictionary.navBar.calendars }}
            </router-link>

            <router-link to="/sectors" class="navLink" @click="showMobileSideBar = false; clickEvent('sectors')"><i
                class="ri-donut-chart-line"/>
              {{ dictionary.navBar.sectors }}
            </router-link>

            <router-link to="/social" class="navLink" @click="showMobileSideBar = false; clickEvent('social trend')"><i
                class="ri-group-line"/>
              {{ dictionary.navBar.socialBuzz }}
            </router-link>
            <!--            <router-link to="/heatmap" class="navLink" @click="showMobileSideBar = false; clickEvent('heatmap')"><i-->
            <!--                class="ri-dashboard-2-line"/>-->
            <!--              {{ dictionary.navBar.heatmap }}-->
            <!--            </router-link>-->
            <router-link to="/top" class="navLink" @click="showMobileSideBar = false; clickEvent('top10')"
                         v-if="!isProUI">
              <i
                  class="ri-medal-2-line"/>
              {{ dictionary.navBar.top10 }}
            </router-link>

            <router-link to="/topro" class="navLink" @click="showMobileSideBar = false; clickEvent('top10Pro')"
                         v-if="isProUI">
              <i
                  class="ri-medal-2-line"/>
              <span>
                {{ dictionary.navBar.top10Pro }}
              </span>
              &nbsp;<div class="newTag">{{ dictionary.navBar.new }}</div>

            </router-link>
<!--            <router-link to="#" class="navLink notActive"-->
<!--                         @click="showMobileSideBar = false; clickEvent('institutional')" v-if="isProUI"><i-->
<!--                class="ri-user-2-line"/>-->
<!--              <span>-->
<!--              {{ dictionary.navBar.institutional }}-->
<!--               &nbsp;<div class="newTag">{{ dictionary.navBar.soon }}</div>-->
<!--            </span>-->
<!--            </router-link>-->
            <router-link to="/dividends" class="navLink" @click="showMobileSideBar = false; clickEvent('dividends')"
                         v-if="isProUI"><i
                class="ri-money-dollar-circle-line"/>
              <span>
              {{ dictionary.navBar.dividends }}
<!--               &nbsp;<div class="newTag">{{ dictionary.navBar.new }}</div>-->
            </span>
            </router-link>
            <router-link to="/settings" v-if="isProUI" class="navLink"
                         @click="showMobileSideBar = false; clickEvent('settings')"><i
                class="ri-settings-3-line"/>
              {{ dictionary.navBar.settings }}
            </router-link>


            <router-link to="/affiliate" v-if="isAffiliate" class="navLink"
                         @click="showMobileSideBar = false; clickEvent('affiliate')"><i
                class="ri-settings-3-line"/>
              Affiliation
            </router-link>

            <!--            <div class="navLink contactButton" @click="showContactUs = true">-->
            <!--            <i class="ri-mail-line"/>-->
            <!--              {{ dictionary.navBar.contactUs }}-->
            <!--            </div>-->

            <div class="navLink terms" @click="acceptedTerms = false; clickEvent('read terms')">
              {{ dictionary.navBar.termsOfUse }}
            </div>

          </nav>
        </Transition>

        <nav id="navBar" v-if="!isProUI">
          <div style="width: 5px;"/>
          <div class="mobile" id="mobileMenu" :class="{whiteMobilMenu: locationIncludes('ibi.')}"
               @click="showMobileSideBar = !showMobileSideBar">
            <i class="ri-menu-line"/>
          </div>
          <img :src="setLogo()" id="logo" :style="setLogoWidth()" style="max-height: 34px;" v-if="logoSrc"/>
          <!--        <img src="https://www.inter-il.com/wp-content/uploads/2021/12/3-4.jpg" id="logo" style="margin-right: 1rem; width: 120px"/>-->


          <router-link to="/" class="navLink desktop" @click="clickEvent('home')"><i class="ri-home-2-line"/>
            {{ dictionary.navBar.home }}
          </router-link>
          <router-link to="/watchlist" v-if="!isProUI" class="navLink desktop" @click="clickEvent('watchlist')"><i
              class="ri-folder-line"/>
            {{ dictionary.navBar.myWatchlist }}
          </router-link>
          <router-link to="/watchlistPro" v-if="isProUI" class="navLink desktop" @click="clickEvent('watchlist')"><i
              class="ri-folder-line"/>
            {{ dictionary.navBar.myWatchlist }}
          </router-link>
          <router-link to="/calendars" class="navLink desktop" @click="clickEvent('calendars')"><i
              class="ri-calendar-line"/>
            {{ dictionary.navBar.calendars }}
          </router-link>
          <router-link to="/sectors" class="navLink desktop" @click="clickEvent('sectors')"><i
              class="ri-donut-chart-line"/>
            {{ dictionary.navBar.sectors }}
          </router-link>
          <!--          <router-link to="/heatmap" class="navLink desktop" @click="showMobileSideBar = false; clickEvent('heatmap')"><i-->
          <!--              class="ri-dashboard-line"/>-->
          <!--            {{ dictionary.navBar.heatmap }}-->
          <!--          </router-link>-->
          <router-link to="/social" class="navLink desktop" @click="clickEvent('social trend')"><i
              class="ri-group-line"/>
            {{ dictionary.navBar.socialBuzz }}
          </router-link>

          <router-link to="/settings" v-if="isProUI" class="navLink desktop" @click="clickEvent('settings')"><i
              class="ri-settings-3-line"/>
            {{ dictionary.navBar.settings }}
          </router-link>

          <router-link to="/settings" v-if="isAffiliate" class="navLink desktop" @click="clickEvent('affiliate')"><i
              class="ri-settings-3-line"/>
            Affiliation
          </router-link>

          <div class="navLink contactButton desktop" v-if="isProUI" @click="showContactUs = true">
            <i class="ri-mail-line"/>{{ dictionary.navBar.contactUs }}
          </div>

          <div class="navLink languageClick">
            <span style="font-size: 16px" @click="showLanguageMenu = !showLanguageMenu">
              <i class="ri-translate-2" style="padding: 1rem; margin: -1rem;"/>
              {{ systemLanguage === 'he' ? 'עבר' : 'ENG' }}
            </span>

            <div class="langList" v-if="showLanguageMenu">
              <p>{{ dictionary.navBar.selectLanguage }}</p>
              <div @click="changeLang('he')" :class="{select: systemLanguage === 'he'}">
                <i class="ri-check-line" v-if="systemLanguage === 'he'"/>
                {{ dictionary.languages.he }}
              </div>
              <div @click="changeLang('en')" :class="{select: systemLanguage === 'en'}">
                <i class="ri-check-line" v-if="systemLanguage === 'en'"/>
                {{ dictionary.languages.en }}
              </div>
            </div>
          </div>

          <div class="navLink terms desktop" @click="acceptedTerms = false; clickEvent('read terms')">
            {{ dictionary.navBar.termsOfUse }}
          </div>

          <!--        <div style="margin-right: auto"/>-->
          <!--          <SymbolsSearch :watchlist="storedWatchlist"/>-->
          <div id="searchSymbolButton" @click="showSearchBar = true">
            <i class="ri-search-line"/>
            {{ dictionary.symbolSearch.inputLabel }}
          </div>
          <div id="byMarketBit" class="desktop">
            By Marketbit.pro
            <img src="https://www.marketbit.pro/gfx/m.163bdefa.svg" alt="Marketbit.pro. Market events for traders."/>
          </div>
          <div style="width: .5rem;"/>
        </nav>


        <nav id="navBar" @mouseenter="hoverMenu = true" :dir="uiDirection" @mouseleave="hoverMenu = false"
             :class="{p: isProUI, menuOn: hoverMenu}" v-if="isProUI">
          <!--            <SymbolsSearch :autowidth="true" :watchlist="storedWatchlist"/>-->

          <a href="javascript: void(0)" class="navLink desktop" @click="showSearchBar = true">
            <i class="ri-search-line"/>
            <span>{{ dictionary.symbolSearch.inputLabel }}</span>
          </a>

          <div class="mobile" id="mobileMenu" :class="{whiteMobilMenu: locationIncludes('ibi.')}">
            <div id="proMobileMenu">
              <i class="ri-menu-line" style="margin: -15px; padding: 15px;"
                 @click="showMobileSideBar = !showMobileSideBar"/>

              <div id="searchSymbolButton" @click="showSearchBar = true">
                <i class="ri-search-line"/>
                {{ dictionary.symbolSearch.inputLabel }}
              </div>
            </div>

          </div>
          <img :src="setLogo()" id="logo" :style="setLogoWidth()" style="max-height: 34px;" v-if="logoSrc"/>


          <router-link to="/" class="navLink desktop" @click="clickEvent('home')"><i class="ri-home-2-line"/>
            <span>{{ dictionary.navBar.home }}</span>
          </router-link>
          <router-link to="/watchlist" v-if="!isProUI" class="navLink desktop" @click="clickEvent('watchlist')"><i
              class="ri-folder-line"/>
            <span>{{ dictionary.navBar.myWatchlist }}</span>
          </router-link>
          <router-link v-else to="/watchlistPro" class="navLink desktop" @click="clickEvent('watchlist')"><i
              class="ri-folder-line"/>
            <span>{{ dictionary.navBar.myWatchlist }}</span>
          </router-link>
          <router-link to="/macro" class="navLink desktop" @click="clickEvent('macro news')"><i
              class="ri-global-line"/>
            <span>{{ dictionary.navBar.macroFeed }}</span>
          </router-link>
          <router-link to="/calendars" class="navLink desktop" @click="clickEvent('calendars')"><i
              class="ri-calendar-line"/>
            <span>{{ dictionary.navBar.calendars }}</span>
          </router-link>
          <router-link to="/sectors" class="navLink desktop" @click="clickEvent('sectors')"><i
              class="ri-donut-chart-line"/>
            <span>{{ dictionary.navBar.sectors }}</span>
          </router-link>


          <router-link to="/topro" class="navLink desktop" @click="clickEvent('settings')" v-if="isProUI"><i
              class="ri-medal-line"/>
            <span>
              {{ dictionary.navBar.top10Pro }}
<!--              <div class="newTag">{{ dictionary.navBar.new }}</div>-->
            </span>
          </router-link>
<!--          <router-link to="#" class="navLink desktop notActive" @click="clickEvent('institutional')" v-if="isProUI"><i-->
<!--              class="ri-user-2-line"/>-->
<!--            <span>-->
<!--              {{ dictionary.navBar.institutional }}-->
<!--               &nbsp;<div class="newTag">{{ dictionary.navBar.soon }}</div>-->
<!--            </span>-->
<!--          </router-link>-->
          <router-link to="/dividends" class="navLink desktop" @click="clickEvent('dividends')" v-if="isProUI"><i
              class="ri-money-dollar-circle-line"/>
            <span>
              {{ dictionary.navBar.dividends }}
<!--              <div class="newTag">{{ dictionary.navBar.new }}</div>-->
            </span>
          </router-link>

          <router-link to="/social" class="navLink desktop" @click="clickEvent('social trend')"><i
              class="ri-group-line"/>
            <span>{{ dictionary.navBar.socialBuzz }}</span>
          </router-link>

          <router-link to="/settings" class="navLink desktop" @click="clickEvent('settings')"><i
              class="ri-settings-3-line"/>
            <span>{{ dictionary.navBar.settings }}</span>
          </router-link>

          <router-link to="/affiliate" class="navLink desktop" @click="clickEvent('affiliate')"><i
              class="ri-hand-coin-line"/>
            <span>Affiliation</span>
          </router-link>

          <a href="javascript: void(0)" class="navLink contactButton desktop" v-if="isProUI"
             @click="showContactUs = true">
            <i class="ri-mail-line"/>
            <span>{{ dictionary.navBar.contactUs }}</span>
          </a>

          <div class="navLink languageClick" v-if="!isProUI">
            <span style="font-size: 16px" @click="showLanguageMenu = !showLanguageMenu">
              <i class="ri-translate-2" style="padding: 1rem; margin: -1rem;"/>
              {{ systemLanguage === 'he' ? 'עבר' : 'ENG' }}
            </span>

            <div class="langList" v-if="showLanguageMenu">
              <p>{{ dictionary.navBar.selectLanguage }}</p>
              <div @click="changeLang('he')" :class="{select: systemLanguage === 'he'}">
                <i class="ri-check-line" v-if="systemLanguage === 'he'"/>
                {{ dictionary.languages.he }}
              </div>
              <div @click="changeLang('en')" :class="{select: systemLanguage === 'en'}">
                <i class="ri-check-line" v-if="systemLanguage === 'en'"/>
                {{ dictionary.languages.en }}
              </div>
            </div>
          </div>

          <div style="margin-top: auto"/>
        </nav>

        <Marquee :prices="prices"/>
        <main class="feed" :dir="uiDirection" :class="{p: isProUI, feedMenuOn: hoverMenu}">

          <div class="resizable" style="position: relative" :style="{ width: '30%' }">

            <div class="dynamicContent">
              <div :class="{p: isProUI}" id="view" @scroll="handleScroll" ref="scrollContainer">

                <audio ref="alertSound" preload="auto" volume="0.5" id="bloop" controls
                       style="position: absolute; visibility: hidden; left: -9999999px;">
                  <source src="./assets/bell.mp3" type="audio/mpeg"/>
                </audio>
                <audio ref="breakingAlertSound" preload="auto" volume="0.5" id="bell" controls
                       style="position: absolute; visibility: hidden; left: -9999999px;">
                  <source src="./assets/boop.mp3" type="audio/mpeg"/>
                </audio>
                <div class="feedTitle">
                  <h3>
                    {{ dictionary.feeds.stocksFeed }}
                    <span id="feedTitleReturn" v-if="feedType === 'sector'">
                  ({{ this.feedSymbols }})

                  <span id="returnToPortfolio">
                    <i class="ri-arrow-go-back-line"/>
                    {{ dictionary.feeds.backToPortfolio }}
                  </span>
                </span>
                  </h3>

                </div>

                <EmptyPortfolio v-if="watchlist && watchlist.watchlist && watchlist?.watchlist.length === 0"/>


                <Feed key="appFeed"
                      ref="appFeed"
                      v-if="watchlist && watchlist.watchlist && watchlist?.watchlist.length > 0"
                      :show-filters="true"
                      :type="'neutral'"
                      :symbols="feedSymbols"
                      :prices="prices.prices"
                      @feedLoaded="feedLoaded"
                />


              </div>
            </div>
          </div>

          <!-- ccontent-->

          <!--          <div class="resizable" style="position: relative" :style="{ width: width + 'px' }">-->
          <!--                    <div class="resizable" style="position: relative" :style="{ width: width + 'px' }">-->


          <div class="changeableContent" :class="{show: changeableContent}" :dir="uiDirection">
            <div class="resize-handle" @mousedown="startResize" :dir="uiDirection">
              <div class="iconContainer">
                <i class="ri-expand-left-right-line"/>
              </div>
            </div>
            <div class="content" ref="changeableContent" :dir="uiDirection"
                 :style="{ width: changeableContentWidth + 'px' }">


              <div class="changeableContentClose" @click="changeableContent = false; resetHash()">
                <div>{{ dictionary.tags.quickLook }}</div>
                <div>
                  <i class="ri-close-line"/> {{ dictionary.tags.close }}
                </div>
              </div>
              <symbol-view v-if="changeableContentSettings.type === 'symbolPreview'" :prices="prices"
                           :previewSymbol="changeableContentSettings.symbol"
                           :key="changeableContentSettings.symbol + ':p'" :isPreview="true"/>
            </div>

          </div>


          <div id="macroNewsFeed" :class="{p: isProUI}" ref="mainViewContainer" @scroll="handleScrollMainContainer">
            <div class="view">
              <router-view
                  v-slot="{Component}"
                  :ref="refName"
                  @feedLoaded="feedLoaded"
                  @joinFilteredFeed="joinFilteredFeed"
                  :userWatchlistList="watchlist?.watchlist || []"
                  @toggleNotifications="toggleNotifications"
                  :type="feedType"
                  :symbols="feedSymbols"
                  :notificationsEnabled="notificationsEnabled"
                  :notificationsAvailable="notificationsAvailable"
                  :show-filters="$route.name !== 'Macro'"
                  :prices="prices.prices"
                  :key="$route.fullPath"
                  name="Side">

                <keep-alive>
                  <component :is="Component"/>
                </keep-alive>
              </router-view>
            </div>

          </div>
        </main>
      </div>
    </div>
  </div>

</template>

<script>


import Feed from "./views/FeedView";
import {getCurrentInstance} from "vue";
import {useEquityPrices} from "./stores/equityPrices";
import {useNewYorkTime} from "./stores/newYorkTime";
import {useWatchlist} from "./stores/watchlist";

// import Socket from './services/socket';
import Socket from './services/ws';
import eventBus from './services/events';
import SymbolsSearch from "./components/ui/SymbolsSearch";
import UserService from './services/user';

import {useWebNotification, useWindowFocus} from '@vueuse/core'
import TopTen from "./views/TopTen";
import Terms from "./components/ui/Terms";
import ContactUs from "./components/ui/ContactUs";

import mixpanel from 'mixpanel-browser';
import EmptyPortfolio from "./components/ui/EmptyPortfolio";
import LockView from "./views/LockView";
import SearchBar from "./components/ui/SearchBar";
import PremiumLockView from "./views/PremiumLockView";
import Toast from "./components/ui/Toast";
import Marquee from "./components/marquee/marquee";
import SymbolView from "./views/SymbolView";
import He from "./dictionary/he";
import BetaOverNotification from "./components/sysNotifications/BetaOverNotification";
import TrialEnded from "./views/settingsView/TrialEnded";
import OverlayUI from "./components/ui/Overlay";
import MBPButton from "./components/ui/controls/MBPButton";
import ToggleSwitch from "./components/ui/ToggleSwitch";
import FirstLoginInstructions from "./components/sysNotifications/FirstLoginInstructions";

export default {
  name: 'Home',
  components: {
    FirstLoginInstructions,
    ToggleSwitch,
    MBPButton,
    OverlayUI,
    TrialEnded,
    BetaOverNotification,
    He,
    SymbolView,
    Marquee,
    Toast,
    PremiumLockView, SearchBar, LockView, EmptyPortfolio, ContactUs, Terms, TopTen, SymbolsSearch, Feed
  },
  data() {
    return {
      systemTheme: 'darkMode',
      isFirstLogin: false,
      isAffiliate: false,
      changeableContentSettings: {},
      changeableContent: false,
      width: window.innerWidth * 0.3,
      changeableContentWidth: window.innerWidth <= 660 ? '100%' : window.innerWidth * 0.33,
      resizing: false,
      appToastMessage: null,
      showPremiumLogin: false,
      trialEnded: false,
      trialEndedUser: null,
      showSearchBar: false,
      hoverMenu: false,
      locked: false,
      prmLock: true,
      termsContent: null,
      showLanguageMenu: false,
      globalKey: 1,
      systemError: false,
      notificationsEnabled: false,
      notificationsAvailable: false,
      showContactUs: false,
      logoSrc: null,
      showMobileSideBar: false,
      userPingged: false,
      acceptedTerms: false,
      socket: null,
      symbolsInView: [],
      storedWatchlist: [],
      watchlist: useWatchlist(),
      refName: 'mainView',
      feedType: 'neutral',
      feedSymbols: []
    }
  },
  setup() {
    const prices = useEquityPrices();
    const app = getCurrentInstance();
    const isTabFocused = useWindowFocus()
    document.documentElement.style.setProperty('--dir', 'rtl');
    document.body.classList.add('rtl')
    return {prices, isTabFocused};
  },
  watch: {
    'socket.isConnected'(current, prev) {

      if (!current) {
        this.socket.reconnect();
      }
    },
    isTabFocused(current, prev) {

      if (current === true && !this.socket.isConnected) {
        this.socket.reconnect();
      }
    },
    $route(current, prev) {

      this.changeableContent = false;
      this.resetHash();

      if (current.name === 'Macro') {

        this.refName = 'newsFeed';
        this.feedType = 'news'

      } else if (current.name === 'Macro') {
        this.refName = 'appFeed';
        this.feedType = 'equity';

      } else if (current.name === 'SectorFeed') {
        this.refName = 'newsFeed';
        this.feedType = 'sector';

      } else if (current.name === 'FilteredFeed') {
        this.refName = 'mainView';
        this.feedType = 'filteredFeed';
      } else {
        this.refName = 'mainView';
        this.feedType = 'neutral'
      }
    }
  },
  methods: {
    resetHash() {
      // window.history.pushState("", document.title, window.location.pathname + window.location.search);
    },
    handleHashChange() {
      const newHash = window.location.hash;
      if (newHash.toString().trim().length === 0) {
        this.changeableContent = false;
      }
    },
    showChangeableContent(type) {

      if (type === null) {
        this.changeableContent = false;
      } else {

        this.changeableContent = true;
        this.changeableContentSettings = type;
      }
    },
    toastMessage(message) {

      this.appToastMessage = message;
      this.$refs.appToastMessage.show(() => {

        this.appToastMessage = null;
      });
    },
    locationIncludes(inc) {

      return window.location.host.includes(inc)
    },
    setLogoWidth() {

      if (location.host.includes('ibi')) {
        return {width: '81px'}
      } else {
        return {width: '90px'}
      }
    },
    setLogo() {
      return require(`./assets/gfx/logos/${this.logoSrc}`)
    },
    async changeLang(selectedLanguage, isBanner = false) {

      if (isBanner) {
        await UserService.saveUserSettings({
          systemLanguage: selectedLanguage,
          newsLanguages: selectedLanguage,
        }, 'languages');

        eventBus.emit('changeUiLanguage', selectedLanguage)
      }


      return new Promise(resolve => {
        UserService.updateSystemLanguage(selectedLanguage);

        setTimeout(() => {
          this.$.appContext.app.config.globalProperties.systemLanguage = selectedLanguage;

          axios.defaults.headers.common['Accept-Language'] = selectedLanguage;

          this.$.appContext.app.config.globalProperties.uiDirection = selectedLanguage === 'he' ? 'rtl' : 'ltr';
          this.$.appContext.app.config.globalProperties.dateFormatLocale = selectedLanguage === 'he' ? 'he-IL' : 'en-US';
          const dictionary = require(`./dictionary/${selectedLanguage}`);
          this.$.appContext.app.config.globalProperties.dictionary = dictionary.default;

          if (this.$.appContext.app.config.globalProperties.uiDirection === 'rtl') {
            document.body.classList.add('rtl');
            document.documentElement.style.setProperty('--dir', 'rtl')
          } else {

            document.body.classList.remove('rtl');
            document.documentElement.style.setProperty('--dir', 'ltr')
          }

          this.globalKey++;
          this.showLanguageMenu = false;

          return resolve(true);
        }, 5);
      });
    },
    async toggleNotifications() {
      if (!this.notificationsEnabled) {
        await this.requestNotificationPermission();
      } else {

      }
    },
    async requestNotificationPermission() {

      // Notification.requestPermission().then(res => {
      // })
      // const permission = await window.;
      // this.notificationsEnabled = permission === 'granted';
    },
    clickEvent(name) {
      try {
        mixpanel.track('user:pageClick', {client: window.location.host, name});
      } catch (e) {

      }

    },
    contactUsSent() {
      this.showContactUs = false;

      try {
        mixpanel.track('user:contactUs', {client: window.location.host})
      } catch (e) {

      }

      setTimeout(() => {

        this.$refs.thankYou.style.display = 'unset'
        this.$refs.thankYou.classList.remove('hide');
        setTimeout(() => {

          this.$refs.thankYou.classList.add('hide');
          setTimeout(() => {
            this.$refs.thankYou.style.display = 'hide'
          }, 1000)
        }, 4000);
      }, 50);
    },
    scrollTopTop() {
      const container = this.$refs.topContainer;
      container.scrollTo({top: 0});
    },
    handleScroll() {

      const container = this.$refs.scrollContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        this.$refs.appFeed.loadNext();
      }
    },
    handleNewsScroll() {
      const container = this.$refs.scrollNewsContainer;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        this.$refs.newsFeed.loadNext();
      }
    },
    handleScrollMainContainer() {
      const container = this.$refs.mainViewContainer;
      if (container.scrollTop + container.clientHeight >= (container.scrollHeight - 50)) {
        eventBus.emit('mainViewScrolledToBottom')
      }
    },
    getStoredWatchlist() {

      const storedWl = localStorage.getItem('wl');
      if (!storedWl) return [];

      return JSON.parse(storedWl);
    },
    handlePreMarketPrice(priceUpdate) {
      this.prices.updatePrice('preMarketPrices', {
        symbol: priceUpdate.symbol,
        price: priceUpdate.data?.price || null,
        changeP: priceUpdate.data?.changeP || null,
        volume: priceUpdate.data?.volume || null,
      });
    },
    handlePostMarketPrice(priceUpdate) {
      this.prices.updatePrice('postMarketPrices', {
        symbol: priceUpdate.symbol,
        price: priceUpdate.data?.price || null,
        changeP: priceUpdate.data?.changeP || null,
        volume: priceUpdate.data?.volume || null,
      });
    },
    handlePriceUpdate(priceUpdate) {

      this.prices.updatePrice('prices', {
        symbol: priceUpdate.symbol,
        price: priceUpdate.data.price,
        changeP: priceUpdate.data.changeP,
        marketCap: priceUpdate.data.marketCap,
        dayHigh: priceUpdate.data.dayHigh,
        dayLow: priceUpdate.data.dayLow,
        yearHigh: priceUpdate.data.yearHigh,
        yearLow: priceUpdate.data.yearLow,
        volume: priceUpdate.data.volume,
        eps: priceUpdate.data.eps,
        pe: priceUpdate.data.pe,
        sharesOutstanding: priceUpdate.data.sharesOutstanding,
        avgVolume: priceUpdate.data.avgVolume,
        priceAvg50: priceUpdate.data.priceAvg50,
        priceAvg200: priceUpdate.data.priceAvg200,
      });
    },
    async acceptedTermsInit() {
      this.acceptedTerms = true;
      await this.pingUser();
    },
    topLoaded(symbols) {
      this.socket.joinSocketRooms(symbols, 'topTen')
    },
    joinFilteredFeed(feedId) {

      this.socket.joinSocketRooms(feedId, 'ff')
    },
    feedLoaded(symbols) {

      this.symbolsInView = symbols;
      this.socket.joinSocketRooms(this.symbolsInView)
    },
    handleSocketConnected() {

      this.socket.joinSocketRooms(this.symbolsInView);
    },
    handleSocketClosed() {

    },
    findInTags(tags, tag) {

      return tags.map(tag => tag.toLowerCase().trim()).includes(tag);
    },
    checkIsBreaking(message) {

      if (message.symbols.find(symbol => symbol.symbol === 'SPY')) {

        return this.findInTags(message.tags, 'econ #s') || this.findInTags(message.tags, 'macro economic events')
      }

      return message.sourceName === 'MarketAlerts' && this.findInTags(message.tags, 'breaking');
    },
    browserNotification(notificationData) {

      let symbolsList = notificationData.symbols.map(symbol => symbol.symbol);
      const watchlist = useWatchlist();
      if (this.isProUI) {
        if (!watchlist.premiumWatchlist?.some(symbol => symbolsList.includes(symbol.symbol))) return;
      } else {
        if (!notificationData.symbols.some(symbol => watchlist?.watchlist.includes(symbol.symbol))) return;
      }


      if (this.isProUI) {

        try {
          const hasSoundNotification = watchlist?.premiumWatchlist?.some(symbol => symbolsList.includes(symbol.symbol) && symbol.soundNotification);
          if (hasSoundNotification) {
            // this.playNotificationAlert();
          }
        } catch (e) {

        }
      } else {
        // this.playNotificationAlert();
      }

      let body = '\u200f' + (this.systemLanguage === 'he' && notificationData.languages?.he ? notificationData.languages?.he : notificationData.title);
      if (notificationData.symbols && notificationData.symbols.length < 4) {

        body = `${symbolsList.join(', ')}: ${body}`;
      }

      let options = {
        title: this.systemLanguage === 'he' ? 'הודעת Marketbit.pro חדשה' : 'New Marketbit.pro notification',
        body,
        dir: 'auto',
        lang: this.systemLanguage,
        renotify: false,
      }


      if (!notificationData.isbreaking && !this.checkIsBreaking(notificationData)) {
        this.playNotificationAlert();

        const {
          isSupported,
          notification,
          show,
          close,
          onClick,
          onShow,
          onError,
          onClose,
        } = useWebNotification(options)

        onClick(() => {
          window.focus();
          close();
        })

        setTimeout(() => {
          window.focus();
          close();
        }, 10000);

        if (isSupported.value) show()
      }
    },
    browserNotificationBreaking(notificationData) {
      if (notificationData.isbreaking || notificationData.isBreaking) {

        let symbolsList = notificationData.symbols.map(symbol => symbol.symbol);

        let body = '\u200f' + (this.systemLanguage === 'he' && notificationData.languages?.he ? notificationData.languages?.he : notificationData.title);
        if (notificationData.symbols && notificationData.symbols.length < 4) {

          body = `${symbolsList.join(', ')}: ${body}`;
        }

        let options = {
          title: this.systemLanguage === 'he' ? 'הודעת Marketbit.pro חדשה' : 'New Marketbit.pro notification',
          body,
          dir: 'auto',
          lang: this.systemLanguage,
          renotify: false,
        }


        this.playBreakingAlertSound();
        const {
          isSupported,
          notification,
          show,
          close,
          onClick,
          onShow,
          onError,
          onClose,
        } = useWebNotification(options)

        onClick(() => {
          window.focus();
          close();
        })

        setTimeout(() => {
          window.focus();
          close();
        }, 10000);

        if (isSupported.value) show()
      }
    },
    navigateEvent(data) {

      if (data.type === 'feed') {

        if (data.value === 'sector') {

          const isFeedSeen = window.getComputedStyle(this.$refs.scrollContainer).display;
          this.feedType = 'sector';
          this.feedSymbols = data.symbol;
          if (isFeedSeen === 'none') {
            this.$router.push(`/feed/sector/${data.symbol}`);
            this.$router.go(1);
          }
          // this.globalKey++;
          // this.$refs.appFeed.loadFeed(true);
          // this.$refs.appFeed.feedKey++;

        }
      }
    },
    openSearchPanel() {

      this.showSearchBar = true;
    },
    cloeSearchPanel() {

      this.showSearchBar = false;
    },
    themeChange(theme) {
      if (theme === 'darkMode') {
        document.documentElement.setAttribute('data-theme', 'dark')
      } else {
        document.documentElement.removeAttribute('data-theme', 'dark')
      }
    },
    async pingUser() {

      try {
        const pingResult = await UserService.pingUser();
        let {watchList, settings, acceptedTerms, systemLangDefault, terms, systemTheme, isFirstLogin} = pingResult;

        if (pingResult.isAffiliate) this.isAffiliate = true;
        this.isFirstLogin = isFirstLogin;


        this.trialEndedUser = {
          specialOffer: pingResult.specialOffer,
          status: pingResult.trialEnded,
          userId: pingResult.userId,
          email: pingResult.email,
          systemLanguage: pingResult.systemLanguage
        }

        this.termsContent = terms;

        if (settings) {
          settings = JSON.parse(settings);

          await this.changeLang(systemLangDefault ? systemLangDefault : 'en')

          if (settings.title) document.title = `${settings.title}`

          const isProUI = settings.p === undefined ? false : settings.p;
          this.$.appContext.app.config.globalProperties.isProUI = isProUI;
          this.$.appContext.app.config.globalProperties.newsLanguages = settings.newsLanguages;
          this.$.appContext.app.config.globalProperties.systemLanguage = systemLangDefault;
          this.$.appContext.app.config.globalProperties.uiDirection = systemLangDefault === 'he' ? 'rtl' : 'ltr'; //settings.defaultDir;
          this.$.appContext.app.config.globalProperties.dateFormatLocale = systemLangDefault === 'he' ? 'he-IL' : 'en-US';

          this.themeChange(systemTheme);

          this.$.appContext.app.config.globalProperties.systemTheme = !isProUI ? 'lightMode' : systemTheme;
          if (systemLangDefault === 'he') {
            document.body.classList.add('rtl');
            document.documentElement.style.setProperty('--dir', 'rtl')
          } else {
            document.body.classList.add('rtl');
            document.documentElement.style.setProperty('--dir', 'ltr')
          }


          if (settings.favicon) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.head.appendChild(link);
            }
            link.href = settings.favicon;
          }

          for (let k in settings.style) {
            if (k !== 'dir') {

              document.documentElement.style.setProperty(`--${k}`, settings.style[k].replace(';', ''));
            }
          }

          if (settings.logo && settings.showLogo) this.logoSrc = settings.logo;
        }


        // this.globalKey++;
        this.userPingged = true;
        this.acceptedTerms = acceptedTerms;

        this.$nextTick(() => {

          if (location.host.includes('ibi.ma')) {
            document.querySelector('.languageClick').style.padding = '5px';
          }
        });

        this.watchList = watchList;
        this.$.appContext.app.config.globalProperties.globalWatchlist = this.watchlist;

        useWatchlist().updateWatchlist(watchList);

        const watchlistArray = Array.from(new Set(...[useWatchlist().watchlist]));
        useWatchlist().$subscribe((mutation, state) => {

          this.$.appContext.app.config.globalProperties.globalWatchlist = state;
          this.socket.updateNotificationRooms();
        });

        this.globalKey++;

      } catch (e) {

        if (e.response) {
          if (e.response.status === 307) {
            if (e.response.data.locked) {
              this.locked = true;
            } else if (e.response.data.prmLock) {

              this.prmLock = true;
            }
          } else {
            if (e.response.data.error.userStatus) {

              const userStatus = e.response.data.error.userStatus;
              if (userStatus === 'TRIAL_ENDED') {
                this.trialEnded = true;
                this.trialEndedUser = e.response.data.error.user;
              }
            }
          }
          return;
        } else {
          console.log(e)
        }
      } finally {
        this.showPremiumLogin = true;
      }

    },
    // beforeRouteEnter(to, from, next) {
    //   if (to.meta.addHash) {
    //     window.location.hash = '#symbolPreview';
    //   }
    //   next();
    // },
    // beforeRouteLeave(to, from, next) {
    //   if (from.meta.addHash) {
    //     window.location.hash = '';
    //   }
    //   next();
    // },
    playNotificationAlert() {
      this.$refs.alertSound.play()
          .then(() => {

          }).catch(e => console.log(e))
    },
    playBreakingAlertSound() {
      this.$refs.breakingAlertSound.play()
          .then(() => {

          }).catch(e => console.log(e))
    },
    startResize() {
      this.resizing = true;
      window.addEventListener('mousemove', this.resize);
      window.addEventListener('mouseup', this.stopResize);
    },
    resize(event) {
      if (this.resizing) {
        this.toggleUserSelect(false);
        const deltaX = this.uiDirection === 'rtl' ? event.clientX : window.innerWidth - event.clientX;
        this.changeableContentWidth = Math.min(Math.max(350, deltaX), window.innerWidth * 0.679); // Limit minimum width
      }
    },
    stopResize() {
      this.resizing = false;
      this.toggleUserSelect(true);
      window.removeEventListener('mousemove', this.resize);
      window.removeEventListener('mouseup', this.stopResize);
    },
    toggleUserSelect(allowSelect) {
      document.body.style.userSelect = allowSelect ? 'auto' : 'none';
    },
    isQuarterlyMinute() {
      const now = new Date();
      const minutes = now.getMinutes();
      return minutes % 15 === 0;
    }
  },
  async beforeMount() {


    await this.pingUser();
    if (!this.trialEnded) {
      // setInterval(async () => {
      //
      //   const isQuarterlyMinute = this.isQuarterlyMinute();
      //   if (isQuarterlyMinute) {
      //     try {
      //
      //       await UserService.pingUser();
      //     } catch (e) {
      //       const userStatus = e.response.data.error.userStatus;
      //       if (userStatus === 'TRIAL_ENDED' && !this.trialEnded) location.reload();
      //     }
      //   }
      // }, 5000);
    }

    this.storedWatchlist = this.getStoredWatchlist()

    if (this.$route.name === 'Macro') {

      this.refName = 'newsFeed';
      this.feedType = 'news'

    } else if (this.$route.name === 'Macro') {
      this.refName = 'appFeed';
      this.feedType = 'equity';

    } else if (this.$route.name === 'SectorFeed') {
      this.refName = 'appFeed';
      this.feedType = 'sector';

    } else if (this.$route.name === 'FilteredFeed') {
      this.refName = 'mainView';
      this.feedType = 'filteredFeed';
    } else {
      this.refName = 'mainView';
      this.feedType = 'neutral'
    }
  },
  created() {

  },
  beforeUnmount() {

    eventBus.off('themeChange', this.themeChange);
    eventBus.off('priceUpdate', this.handlePriceUpdate);
    eventBus.off('preMarketPrice', this.handlePreMarketPrice);
    eventBus.off('postMarketPrice', this.handlePostMarketPrice);
    eventBus.off('socketConnected', this.handleSocketConnected);
    eventBus.off('socketClosed', this.handleSocketClosed);
    eventBus.off('newEquityNotification', this.browserNotification);
    eventBus.off('newNewsNotification', this.browserNotificationBreaking);
    eventBus.off('navigate', this.navigateEvent);
    eventBus.off('changeUiLanguage', this.navigateEvent);
    eventBus.off('closeSearchPanel', this.cloeSearchPanel);
    eventBus.off('openSearchPanel', this.openSearchPanel);
    eventBus.off('toastMessage', this.toastMessage);
    eventBus.off('showChangeableContent', this.showChangeableContent);

    window.removeEventListener('hashchange', this.handleHashChange);

  },
  async mounted() {


    const newYorkTime = useNewYorkTime();
    newYorkTime.getNyTime();


    console.log(newYorkTime.isPreMarket)
    eventBus.on('showChangeableContent', this.showChangeableContent);
    eventBus.on('themeChange', this.themeChange);
    eventBus.on('newEquityNotification', this.browserNotification);
    eventBus.on('newNewsNotification', this.browserNotificationBreaking);
    eventBus.on('socketConnected', this.handleSocketConnected);
    eventBus.on('priceUpdate', this.handlePriceUpdate);
    eventBus.on('preMarketPrice', this.handlePreMarketPrice);
    eventBus.on('postMarketPrice', this.handlePostMarketPrice);
    eventBus.on('navigate', this.navigateEvent);
    eventBus.on('changeUiLanguage', this.changeLang);
    eventBus.on('closeSearchPanel', this.cloeSearchPanel);
    eventBus.on('openSearchPanel', this.openSearchPanel);
    eventBus.on('toastMessage', this.toastMessage);

    window.addEventListener('hashchange', this.handleHashChange);


    this.$.appContext.app.config.socket = Socket();
    this.socket = this.$.appContext.app.config.socket;
    mixpanel.track('user:view', {client: window.location.host})

    setTimeout(() => {
      this.socket.updateNotificationRooms();
    }, 1000)
  }
}
</script>

<style lang="scss">

@import "./assets/style/vars";
@import "./assets/style/general";

.resizable {
  //padding: 5px;
  //border-radius: 8px;
  //overflow: hidden;
  flex: 0 1 auto;
  @media(max-width: 660px) {
    display: none;
  }
}

#appWrapper {
  display: flex;
  flex-direction: column;
}


#navBar {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: .5rem 0;
  background: var(--navBarBgColor);
  border-bottom: 2px solid var(--notficationBorderColor);
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);


  &.p {
    position: fixed;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: unset;
    border-right: 2px solid #111;
    //border-right: 2px solid var(--notficationBorderColor);
    width: 36px;
    padding: .5rem;
    height: calc(100vh);
    box-sizing: content-box;
    overflow: hidden;
    white-space: nowrap;

    @media(max-width: 660px) {
      width: 100%;
      height: 26px;
    }

    &[dir="rtl"] {
      right: 0;
      left: unset;
      border-right: unset;
      border-left: 2px solid #111;
    }

    a {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
        opacity: 0;
        width: 0;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;


      }
    }
  }


  &.menuOn {

    width: 200px;
    box-shadow: 5px 0 35px 0 rgba(0, 0, 0, 0.15);

    a {
      span {
        opacity: 1 !important;
        width: 100% !important;
      }
    }
  }

  @media(max-width: 660px) {

  }

}

.newTag {
  background: var(--lightYellow);
  color: var(--bgColor);
  font-weight: 500;
  font-size: 11px;
  border-radius: 4px;
  padding: 0 2px;
  width: fit-content;
  display: inline-flex;
  @media(max-width: 660px) {
    font-size: 16px;
    padding: 2px;
  }
}

.resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 12px;
  cursor: ew-resize;
  background-color: black;
  z-index: 11;

  &[dir="rtl"] {
    left: unset;
    right: 0;
  }

  @media(max-width: 660px) {
    display: none;
  }

  &:hover {
    background-color: #333333;
  }

  .iconContainer {
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
    }
  }
}

.navLink {
  padding: 5px 1rem;
  font-size: 18px;
  text-decoration: none;
  color: var(--navBarLinkColor);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;

  @media(max-width: 660px) {
    border-radius: 0;
  }

  &:hover {
    background: var(--highlightColorOpacity);
    color: var(--highlightColor);
  }
}


.navLink.router-link-active:not(.notActive) {
  color: var(--navBarLinkActiveColor);
  background: var(--highlightColor);
}

.navLink.desktop {
  font-size: 14px;
  padding: 5px 10px;
}


.feed {
  display: flex;
  align-items: flex-start;
  background: var(--bgColor);
  gap: 2px;
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);

  &.feedMenuOn {
    //transform: translateX(100px);
    overflow-x: hidden;
  }

  &.p {
    background: #111;
    padding-left: 54px;
    height: 96.3vh;
    overflow: hidden;
    @media (max-width: 1460px) {
      height: 94.54vh;
    }
    @media(max-width: 660px) {
      padding-left: 0;
      padding-top: 0;
      height: unset;
      overflow: unset;
    }

    &[dir="rtl"] {
      padding-right: 54px;
      padding-left: unset;
      @media(max-width: 660px) {
        padding-right: 0;
      }
    }
  }

  &[dir='ltr'] {
    //flex-direction: row-reverse;
  }

  @media(max-width: 660px) {
    gap: 0;
  }

  #view {
    overflow-y: auto;
    height: calc(100vh - 52px);
    background: var(--bgColor);
    width: 100%;
    @media(max-width: 660px) {
      display: none;
    }

    &.p {
      height: calc(100vh);
      transition: all .2s ease-in;

      &.contract {
        height: 50vh;
      }
    }
  }

  #macroNewsFeed {
    //height: calc(100vh - 52px);
    overflow-y: auto;
    background: var(--bgColor);
    width: 70%;
    margin: 0 5px 0 0;
    height: calc(100vh - 35px);

    &.p {
      margin: 0px 2px 0 0;
    }

    @media(max-width: 660px) {
      width: 100%;
      margin: 0;
    }
  }

  #sidePane {
    //overflow-y: auto;
    overflow: hidden;
    height: calc(100vh - 52px);
    width: 30%;
    background: var(--bgColor);
    @media(max-width: 660px) {
      display: none;
    }
  }
}

#byMarketBit {
  font-weight: 600;
  color: #42b983;
  background: black;
  padding: 5px 10px;
  border-radius: 8px;
  direction: ltr;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 22px;
  }
}


.desktop {
  @media screen and (max-width: 660px) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: 660px) {
    display: none;
  }
}

#mobileMenu {
  padding: 10px;
  margin: -10px;
  width: 95%;
}

.whiteMobilMenu {
  color: white;
}

#mobileNavBar {
  position: fixed;
  right: 0;
  border-left: 2px solid var(--notficationBorderColor);
  box-shadow: 0 5px 25px 0 rgba(15, 15, 15, .15);
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--navBarBgColor);
  z-index: 99999999999999;
  overflow-y: auto;

  a {
    border-bottom: 1px solid var(--notficationBorderColor);
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
}

.contactButton {
  color: #42b983;
  background: rgba(66, 185, 131, 0.15);
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: #42b983;
    background: rgba(66, 185, 131, 0.15);
  }
}

#contactUsSent {
  position: fixed;
  transition: all .4s ease-in;
  left: 50%;
  top: 2rem;
  z-index: 9;
  background: var(--bgColor);
  padding: 1rem;
  border-radius: 8px;
  transform: translateX(-50%);
  box-shadow: 0 5px 25px 0 var(--highlightColorOpacity), 0 5px 55px rgba(0, 0, 0, 0.2), 0 5px 55px 10px var(--highlightColorOpacity);
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  span {
    display: block;
    margin-bottom: -15px;
    font-size: 20px;
    font-weight: 600;
  }
}

#contactUsSent.hide {
  transform: translate(-50%, 2.5rem);
  opacity: 0;
}

.terms {
  margin-right: auto;
}

.notificationsToggle {
  background: var(--highlightColor);
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  margin: -5px;
}


.expl {
  background: var(--dailyBriefBgColor);
  padding: $smallGap;
  margin: 5px;
  border-radius: 8px;
  color: var(--highlightColor);
  font-weight: 500;
}

.expl.alert {
  color: white;
  font-size: 18px;
}

.portfolioLink {
  background: var(--highlightColor);
  color: white;
  display: block;
  width: fit-content;
  text-align: center;
  margin: 25px auto;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

#systemUpdate {
  text-align: center;
  padding-top: 2rem;
  color: black;
}

.languageClick {
  position: relative;
  flex: 0 0 fit-content;

  &:hover {
    color: black;

    div {
      color: black;
    }
  }

  .langList {

    p {
      font-weight: 600;
      margin: 0;
      padding: 5px 10px 5px;
      border-bottom: 1px solid var(--notficationBorderColor);
    }

    div {
      margin: 0;
      padding: 5px 10px;

      &:hover {
        background: var(--tableHoverColor);
      }
    }


    div.select {
      color: var(--highlightColor);
    }

    overflow: hidden;
    position: absolute;
    z-index: 9999;
    background: var(--bgColor);
    width: 220px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35);
    transform: translateY(10px);
  }
}

#feedTitleReturn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;

  #returnToPortfolio {
    font-size: 12px;
    cursor: pointer;
  }
}

#searchSymbolButton {
  font-weight: bolder;
  border-radius: 8px;
  background: var(--highlightColor);
  color: var(--bgColor);
  cursor: pointer;
  padding: 5px 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 1px var(--highlightColorOpacity);
  flex: 0 0 fit-content;
}

#proMobileMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dynamicContent {
  transition: all .2s ease-in;
  //height: 50%;
}

.changeableContent {
  transition: all .2s ease-in;
  border-top: 5px solid var(--notficationBorderColor);
  position: fixed;
  right: 0;
  transform: translateX(110%);
  height: 100%;
  z-index: 99999;
  border-left: 2px solid var(--notficationBorderColor);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
  background: var(--bgColor);
  padding-top: 32px;

  @media(max-width: 660px) {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 70px;
  }

  &[dir="rtl"] {
    border-left: unset;
    border-right: 2px solid var(--notficationBorderColor);
    transform: translateX(-110%);
    right: unset;
    left: 0;
  }

  .changeableContentClose {
    z-index: 12;
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid var(--notficationBorderColor);
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    font-weight: 700;
    background: var(--lightYellow);
    color: var(--bgColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 660px) {
      top: unset;
      position: fixed;
      bottom: 43px;

      left: 0;
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid var(--notficationBorderColor);
    }
  }

  .content {
    overflow-y: auto;
    height: 100%;
    width: calc(100% - 5px);
    margin-left: 10px;

    &[dir="rtl"] {

      margin-right: 10px;
    }

    @media(max-width: 660px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      box-sizing: border-box;
    }
  }

  &.show {
    transform: translateX(0);
    overflow-y: auto;
  }
}

</style>
