/**
 * New York local time.
 */

import {DateTime} from 'luxon';
import {defineStore} from 'pinia'

export const useNewYorkTime = defineStore('nyTime', {

    state: () => {
        return {
            time: DateTime.local().setZone('America/New_York').toFormat('HH:mm:ss'),
            isMarketOpen: false,
            isPreMarket: false,
            isPostMarket: false
        }
    },
    actions: {
        setIsMarketOpen() {

            const HOLIDAYS = ['2024-01-01', '2024-01-15', '2024-02-19', '2024-05-27', '2024-06-19', '2024-07-04', '2024-09-02', '2024-10-14', '2024-11-11', '2024-11-28', '2024-12-25', '2025-01-01', '2025-01-20', '2025-02-17', '2025-05-26', '2025-06-19', '2025-07-04', '2025-09-01', '2025-10-13', '2025-11-11', '2025-11-27', '2025-12-25', '2026-01-01', '2026-01-19', '2026-02-16', '2026-05-25', '2026-06-19', '2026-07-04', '2026-09-07', '2026-10-12', '2026-11-11', '2026-11-26', '2026-12-25'];
            const day = new Date().getDay();
            const blockDays = [0, 6];

            if (HOLIDAYS.includes(new Date().toISOString().split('T')[0]) || blockDays.includes(day)) {

                this.isMarketOpen = false;
            } else {

                if (this.time === '09:30:00') location.reload();

                const now = DateTime.now().setZone('America/New_York');

                let marketStart = now.set({hour: 9, minute: 30, second: 0});
                let marketEnd = now.set({hour: 16, minute: 0, second: 0});
                let preMarketStart = now.set({hour: 4, minute: 0, second: 0});
                let preMarketEnd = now.set({hour: 9, minute: 30, second: 0});
                let postMarketEnd = now.set({hour: 3, minute: 30, second: 0});
                let postMarketStart = now.set({hour: 16, minute: 0, second: 0});

                let isMarketOpen = now >= marketStart && now < marketEnd
                if (isMarketOpen) {
                    this.isMarketOpen = true;
                    this.isPostMarket = false;
                    this.isPreMarket = false;
                } else {

                    if (
                        now >= preMarketStart && now < preMarketEnd ||
                        now >= postMarketStart && now < postMarketEnd ||
                        now <= preMarketStart.plus({days: 1})
                    ) {
                        if (now >= preMarketStart && now < preMarketEnd) {
                            this.isPreMarket = true;
                            this.isPostMarket = false;
                        } else if ((now >= postMarketStart && now < postMarketEnd) || now <= preMarketStart.plus({days: 1})) {
                            this.isPostMarket = true;
                            this.isPreMarket = false;
                        }
                    }
                }
            }
        },
        getNyTime() {

            this.time = DateTime.local().setZone('America/New_York').toFormat('HH:mm:ss');
            this.setIsMarketOpen();

            setInterval(() => {
                this.time = DateTime.local().setZone('America/New_York').toFormat('HH:mm:ss');
                this.setIsMarketOpen();
            }, 1000);
        }
    }
});
