export default {
    navBar: {
        home: 'עמוד הבית',
        myWatchlist: 'רשימת מעקב',
        calendars: 'יומנים',
        settings: 'הגדרות',
        socialBuzz: 'מניות טרנדיות',
        sectors: 'ביצועי סקטורים',
        stocksFeed: 'חדשות מניות במעקב',
        macroFeed: 'מסביב לשוק',
        indices: 'מדדים מובילים',
        top10: 'המניות המובילות',
        top10Pro: 'ביצועי שוק',
        contactUs: 'צור קשר',
        institutional: 'אחזקות מוסדיים',
        dividends: 'דיבידנדים',
        termsOfUse: 'תנאי שימוש',
        selectLanguage: 'בחירת שפת מערכת',
        heatmap: 'מפת חום',
        new: 'חדש',
        soon: 'בקרוב'
    },
    contactUs: {
      title: 'יצירת קשר',
        text: 'אנחנו משתדלים לספק לכם את המידע הרלוונטי והאיכותי ביותר בזמנים המהירים ביותר. נשמח לשמוע מכם כל רעיון, הצעת\n' +
            '        שיפור או דיווח על תקלה.',
        name: 'שם',
        email: 'כתובת אי-מייל',
        message: 'הודעה',
        privacy: 'פרטיותכם חשובה לנו. המידע נועד כדי לענות לפנייתכם ולא יועבר לצד שלישי או ישמש למשלוח ספאם.'
    },
    languages: {
        he: 'עברית',
        en: 'אנגלית'
    },
    login: {
        trialEnded: {
            title: 'מנוי הניסיון ל-Marketbit.pro הסתיים',
            explain: (price)=> `<b>תודה שהתנסת בפלטפורמה!</b>
              <br/>כדי לא לפספס הזדמנויות מסחר הרשמו עכשיו למנוי פרימיום בעלו של ${price} לחודש.<br/>
              לכל שאלה או בעיה ניתן לפנות לאי-מייל <a class="mbpLink" href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>.`,
            signupButton: 'הרשמה למנוי פרימיום'
        }
    },
    tags: {
        quickLook: 'מבט מהיר',
        close: 'סגירה',
        topActive: 'הפעילות ביותר',
        topLosers: 'המפסידות ביותר',
        topGainers: 'המרוויחות ביותר',
        moreSymbols: 'נוספות',
        moreEvents: 'אירועים נוספים',
        hideSymbols: 'הסתר',
        insiders: 'בעלי עניין',
        tradingHalt: 'עצירת מסחר',
        tradingResumption: 'המשך מסחר',
        trade: 'טרייד',
        signal: 'סיגנל טכני',
        pressRelease: 'הודעה לעיתונות',
        shouldKnowNotificationTitle: 'כדאי לדעת',
        marketOpen: 'המסחר פתוח',
        marketClose: 'המסחר סגור',
        preMarket: 'טרום מסחר',
        postMarket: 'אחרי מסחר'
    },
    buttons: {
        en: 'אנגלית',
        he: 'עברית',
        continueToApp: 'חזרה ל-Marketbit.pro',
        seeFullDetails: 'לפרטים המלאים',
        send: 'שליחה',
        filterStocks: 'סינון מניות',
        editColumns: 'עריכת עמודות',
        showMoreInfo: 'הצגת מידע נוסף',
        hideMoreInfo: 'הסתרת מידע נוסף',
        approveTerms: 'אני מאשר/ת את תנאי השימוש',
        moveToPortfolio: 'לחצו להוספת סימולים',
        addToPortfolio: 'הוספה למעקב',
        removeFromPortfolio: 'הסרה ממעקב',
        addToPortfolioShort: 'הוספה',
        removeFromPortfolioShort: 'הסרה',
        readMore: 'קרא עוד',
        readLess: 'קרא פחות'
    },
    watchlist: {
        numberOfSymbols: 'סימולים במעקב'
    },
    filters: {
        sector: 'סקטור',
        industry: 'תעשיה',
        sectors: 'סקטורים',
        industries: 'תעשיות',
        searchType: '(ניתן להקליד ולחפש)',
        pe: 'מכפיל הון (P/E)',
        marketCap: 'שווי שוק',
        priceChange: 'שינוי מחיר',
        moreThan: 'מעל',
        lessThan: 'מתחת',
        andMore: 'ומעלה',
        andLess: 'ומטה',
        years: 'שנים',
        typeAndEnter: 'כתיבה חופשית (לחיצה על Enter לסיום)',
        dividendConsecutiveYears: 'שנות תשלום ברצף',
        dividendPayoutRatio: 'מכפיל דיבידנד (Payout ratio)',
        dividendYield: 'תשואת דיבידנד',
        dividendFrequency: 'תדירות דיבידנד',
        marketCapsList: [
            {
                label: 'Micro-Cap ( < $250M)',
                value: 'microCap'
            },
            {
                label: 'Small-Cap ($250M - $2B)',
                value: 'smallCap'
            },
            {
                label: 'Mid-Cap ($2B - $10B)',
                value: 'midCap'
            },
            {
                label: 'Large-Cap ($10B - $200B)',
                value: 'largeCap'
            },
            {
                label: 'Mega-Cap ( > $200B)',
                value: 'megaCap'
            }
        ],
        dividendFrequencyList: [{
            label: 'רבעוני',
            value: 'quarterly'
        }, {
            label: 'חצי-שנתי',
            value: 'semiYearly'
        }, {
            label: 'שנתי',
            value: 'Yearly'
        }],
        select: 'בחירת',
        newFilter: 'חזרה לסינון',
        resetFilter: 'איפוס סינון',
        applyFilter: 'החל סינון',
        seeNews: 'ראו חדשות מסוננות',
        results: 'תוצאות',
        found: 'נמצאו',
        symbol: 'סימול',
        periods: [
            {
                value: '1D',
                label: '1י'
            },
            {
                value: '5D',
                label: '5י'
            },
            {
                value: '1M',
                label: '1ח'
            },
            {
                value: '6M',
                label: '6ח'
            },
            {
                value: 'YTD',
                label: 'מתחילת השנה'
            },
            {
                value: '1Y',
                label: '1ש'
            },
            {
                value: '3Y',
                label: '3ש'
            }
        ],
    },
    search: {
        tabs: {
            freeSearch: 'חיפוש חופשי',
            filter: 'סינון לפי פרמטרים',
            trending: 'מניות טרנדיות'
        },
        filter: {
            name: 'שם',
            price: 'מחיר',
            mustSelectASector: 'חובה לבחור סקטור או תעשיה. ניתן לחפש תעשיות בהקלדה',
            sectorSelection: 'בחירת סקטור',
            industrySelection: 'בחירת תעשיה (ניתן להקליד ולחפש)',
            marketCapSelection: 'בחירת שווי שוק',
            priceChange: 'שינוי מחיר',
            moreThan: 'מעל',
            lessThan: 'מתחת',
            andMore: 'ומעלה',
            andLess: 'ומטה',
            search: 'סינון',
            pe: 'מכפיל הון (P/E)',
            newFilter: 'חזרה לסינון',
            resetFilter: 'איפוס סינון',
            seeNews: 'ראו חדשות מסוננות',
            results: 'תוצאות',
            found: 'נמצאו',
            symbol: 'סימול',
            periods: [
                {
                    value: '1D',
                    label: '1י'
                },
                {
                    value: '5D',
                    label: '5י'
                },
                {
                    value: '1M',
                    label: '1ח'
                },
                {
                    value: '6M',
                    label: '6ח'
                },
                {
                    value: 'YTD',
                    label: 'מתחילת השנה'
                },
                {
                    value: '1Y',
                    label: '1ש'
                },
                {
                    value: '3Y',
                    label: '3ש'
                }
            ],
            sector: 'סקטור',
            industry: 'תעשיה',
            marketCap: 'שווי שוק',
            priceChangeValues: [{
                value: 0,
                label: '0%'
            }, {
                value: 10,
                label: '10%'
            }, {
                value: 20,
                label: '20%'
            }, {
                value: 30,
                label: '30%'
            }, {
                value: 40,
                label: '40%'
            }, {
                value: 50,
                label: '50%'
            }, {
                value: 60,
                label: '60%'
            }, {
                value: 70,
                label: '70%'
            }, {
                value: 80,
                label: '80%'
            }],
            marketCapRangeValues: [
                {
                    value: '<500m',
                    label: 'מתחת ל-$500M'
                },
                {
                    value: '500m-1b',
                    label: '$500M-$1B'
                },
                {
                    value: '1b-10b',
                    label: '$1B-$10B'
                },
                {
                    value: '10b-200b',
                    label: '$10B-$200B'
                },
                {
                    value: '>200b',
                    label: 'מעל ל-$200B'
                }
            ],
            marketCapValues: [
                {
                    value: '<10m',
                    label: 'מתחת ל-$10M'
                },
                {
                    value: '>10m',
                    label: 'מעל ל-$10M'
                },
                {
                    value: '>100m',
                    label: 'מעל ל-$100M'
                },
                {
                    value: '>1b',
                    label: 'מעל ל-$1B'
                },
                {
                    value: '>10b',
                    label: 'מעל ל-$10B'
                },
                {
                    value: '>100b',
                    label: 'מעל ל-$100B'
                }
            ],
        }
    },
    feeds: {
        viewImage: 'צפייה בתמונה',
        viewLink: 'צפייה בקישור',
        stocksFeed: 'חדשות מניות במעקב',
        macroFeed: 'מסביב לשוק',
        backToPortfolio: 'לרשימת המעקב',
        filters: {
            all: 'כל ההודעות',
            pr: 'הודעות לעיתונות',
            earnings: 'דו״חות',
            tradesAndSignals: 'טריידים וסיגנלים',
            hedgeFunds: 'קרנות גידור',
            sec: 'SEC',
            financialMedia: 'אתרים פיננסים',
            priceTargets: 'אנליסטים',
            analysts: 'BZ wire'
        }
    },
    tables: {
      symbol: 'סימול',
        '1d' : 'יום %',
        '5d': '5י %',
        '1m': '1ח %',
        '6m' : '6ח %',
        'q' : 'רבעון %',
        ytd: 'YTD %',
        '1y' : '1ש %',
        '3y' : '3ש %',
        '5y' : '5ש %',
        sector: 'סקטור',
        industry: 'תעשיה',
        marketCap: 'שווי שוק',
        pe: 'מכפיל הון (P\\E)',
        nextDividendExDate: 'תאריך דיב׳ הבא',
        datePublished: 'תאריך פרסום',
        lastUpdate: 'עדכון אחרון',
        numOfFollowups: 'מס׳ עדכונים',
    },
    trades: {
        percentChange: '% שינוי',
        percentChangeTooltip: 'אחוז שינוי מחיר המניה מזמן פרסום הטרייד.',
        since: 'מאז',
        gained: 'עלתה ב-',
        lost: 'ירדה ב-',
        dateAdded: 'תאריך פרסום',
        lastUpdate: 'תאריך עדכון',
        numberOfFollowups: 'מס׳ עדכונים',
        published: 'פורסם',
        updated: 'עודכן',
        daysAgo: ' ימים',
        tradeHasFollowups: (num) => `לטרייד זה יש <span class="highlight">${num}</span> עדכונים.`,
        sinceTradeWasPublished: (symbol) => `מאז שהטרייד פורסם מניית  <b>${ symbol }</b>`,
    },
    messages: {
        followup: 'עדכון לטרייד',
        enlargeImage: 'לחצו על התמונה להגדלה',
        welcomeBanner: 'ברוכים הבאים ל-Marketbit.pro!',
        languageBanner: 'נא לבחור שפת מערכת:',
        topMktCap: 'ההתייחסות בעמוד זה לחברות בעלות שווי שוק של $50M ומעלה והיקף מסחר של 150K ומעלה',
        topVolume: 'ההתייחסות בעמוד לזה לחברות בעלות היקף מסחר של 150K ומעלה',
        peersExpl: (symbol) => `מניות אשר נבדקו לפי מספר פרמטרים ובעלות שווי שוק בסדר גודל של מניית ${symbol}`,
        shortInstructions: 'Marketbit.pro מאפשרים לכם לקבל חדשות ואירועים בשוק ההון בזמן אמת, באופן אוטומטי וללא מאמץ. כדי לקבל רשימת חדשות עבור המניות שמעניינות אתכם הוסיפו סימולים לרשימת המעקב. ניתן להוסיף ולהסיר סימולים מרשימת המעקב בכל עת.',
        contactMessageSentTitle: 'הודעתך נשלחה!',
        contactUsThankYou: 'תודה רבה על הפנייה, אנו נענה בהקדם',
        watchlist: 'חפשו סימולים והוסיפו אותם לרשימת המעקב שלכם. הרכבת הרשימה תאפשר לכם לקבל חדשות ואירועים רלוונטיים לכם לפני כולם.',
        trendingStocks: 'מניות בולטות בדיונים ברשתות החברתיות. שינויים באזכורי המניה יכולים להצביע על טרנד הולך ומתהווה ברשת החברתית.',
        exDiv: 'בעת מועד ה-X דיבידנד יורדת המניה בשיעור הדיבידנד, ולאחר מכן - רק מי שהחזיק במניה במועד ה-X מקבל את הדיבידנד.',
        sectors: 'כאן ניתן לראות את ביצועי הסקטורים השונים במהלך לאורך זמן. בלחיצה על שם הסקטור ניתן לראות את הסימולים המרכיבים את הסקטור, ולסדר אותם לפי פרמטרים שונים.'
    },
    errors: {
        noContentMessage: 'נא לרשום תוכן להודעה',
        invalidEmail: 'נא לציין כתובת אי-מייל תקינה',
        emptyCalendar: 'לא נמצאו אירועים לתקופה זו',
        emptyWatchlist: 'רשימת המעקב ריקה'
    },
    symbolSearch: {
        frequentlySearched: 'חיפושים נפוצים',
        inputLabel: 'חיפוש סימולים',
        inputLabelNote: 'ניתן לחפש מספר סימולים או שמות ניירות מופרדים על ידי רווח',
        noResults: 'לא נמצאו תוצאות עבור'
    },
    settingsPage: {
        titles: {
            title: 'הגדרות מערכת',
            notificationSettings: 'הגדרות התראות',
            userSettings: 'הגדרות משתמש',
            systemSettings: 'הגדרות מערכת',
            subscriptionSettings: 'הגדרות מנוי',
            languageSettings: 'הגדרות שפה',
            dangerZone: 'אזור סכנה',
            systemLanguage: 'שפת ברירת-מחדל',
            newsLanguages: 'שפות הודעות',
            verifyClosingOfAccount: 'אישור סגירת חשבון'
        },
        buttons: {
            close: 'סגירה',
            saveLanguageSettings: 'שמירת הגדרות שפה',
            saveNotifications: 'שמירת הגדרות התראות',
            saveAccount: 'שמירת הגדרות חשבון',
            closeAccount: 'סגירת חשבון',
            logout: 'התנתקות מהחשבון',
            subscribeNow: 'הרשמו עכשיו למנוי Marketbit.pro פרימיום',
            subscribe: 'הרשמה',
            approveClosingOfAccount: 'הבנתי, אפשר לסגור את החשבון'
        },
        tabs: {
            account: 'חשבון',
            notifications: 'התראות'
        },
        notes: {
            dataUpdate: 'העדכון נשמר',
            updatePassword: 'נא לעדכן סיסמא',
            account: 'ניהול החשבון והמנוי שלך. ניתן לבטל את החשבון והמינוי בכל רגע.',
            notifications: 'בחרו התראות לאי-מייל. ניתן לקבל התראות בזמן אמת, סיכום בזמנים קבועים במידה ויש, או לבטל אותן לגמרי.',
            googleAuth: 'נרשמת לחשבון Marketbit.pro שלך דרך Google. לאחר שינוי כתובת האי-מייל שלך לא יהיה ניתן להתחבר בעזרת Google שנית.',
            verifyClosingOfAccount: 'נא לאשר סגירת חשבון. לאחר מכן לא תהיה אפשרות להתחבר למשתמש זה, לקבל התראות או שירות אחר והתשלומים, במידה וקיימים, יפסקו.',
            expiration: (date, days) => {
                return `תקופת הניסיון שלך תפוג בעוד <b>${days}</b> ימים, בתאריך ${date}<br/>הרשמו לחשבון הפרימיום בעלות של $34.99 לחודש בלבד.`
            }
        },
        account: {
            labels: {
                systemTheme: 'צבעי מערכת',
                lightMode: 'מצב בהיר',
                darkMode: 'מצב כהה',
                firstName: 'שם פרטי',
                lsatName: 'שם משפחה',
                password: 'סיסמא חדשה',
                email: 'אי-מייל',
                subscriptionDetails: 'פרטי מנוי',
                cancelSubscription: 'ביטול מנוי',
                updatePaymentCard: 'עדכון אמצעי תשלום',
                subscriptionPrice: 'מחיר מנוי',
                nextPayment: 'תאריך הבא לתשלום'
            }
        },
        notificationLabels: {
            portfolio: 'חדשות הפורטפוליו',
            breakingNews: 'חדשות מתפרצות',
            vip: 'VIP',
            oilGas: 'נפט וגז',
            banks: 'בנקים',
            commodities: 'סחורות',
            econData: 'מידע כלכלי',
            emailNotifications: 'התראות אי-מייל',
            browserNotifications: 'התראות דפדפן',
            soundNotifications: 'התראות צליל'
        },
        notificationPeriodLabels: {
            none: 'ללא',
            realTime: 'זמן אמת',
            five_min: 'כל 5 דקות',
            fifteen_min: 'כל 15 דקות',
            thirty_min: 'כל 30 דקות',
            fortyfive_min: 'כל 45 דקות',
            sixty_min: 'כל שעה'
        }
    },
    symbolPage: {
        moreStocks: 'מניות שעשויות לעניין אותך',
        findMoreStocks: 'איתור מניות מעניינות',
        findMoreStocksText: (symbol) => `איתור מניות דומות ל-<b>${symbol}</b> על סמך ביצועים ובעלות שווי שוק דומה.`,
        clickToFindMoreStocks: 'לחצו כאן כדי למצוא מניות נוספות',
        performanceOverTime: 'שינוי מחיר לאורך זמן',
        epsNote: 'השוני בין ה-eps למניה, כפי שמחושב אצלנו בדף מניה, וכן ברוב האתרים הפיננסים האחרים עלול לנבוע מכך שבעמוד המניה מדובר על הרווח החשבונאי, הכולל הוצאות והכנסות חד פעמיות ולא מייצגות לדעת הנהלת החברה. בעוד בביצועי החברה בדרך כלל יופיע "הרווח המתואם", כלומר ללא ההוצאות וההכנסות החד פעמיות, כפי שהוגדרו בידי הנהלת החברה.',
        pe: 'P/E',
        eps: 'EPS',
        nextEarnings: 'תאריך דו״ח הבא',
        releaseTime: 'זמן פרסום',
        estimatedEps: 'EPS מוערך',
        nextDividend: 'הדיבידנד הבא',
        exDate: 'תאריך-Ex',
        paymentDate: 'תאריך תשלום',
        nextSplitDate: 'תאריך פיצול הבא',
        splitRatio: 'יחס פיצול',
        about: 'אודות החברה',
        price: 'מחיר',
        name: 'שם חברה',
        symbol: 'סימול',
        marketCap: 'שווי שוק',
        sector: 'סקטור',
        industry: 'תעשיה',
        volume: 'מחזור',
        unusualVolume: 'מעל הממוצע',
        avgVolume: 'מחזור ממוצע',
        sharesOutstanding: 'מספר מניות',
        dailyRange: 'טווח יומי',
        fiftyTwoWeeks: '52 שבועות',
        previousClose: 'סגירה קודמת',
        TYRevenueChange: 'שינוי הכנסות 3 שנים',
        TYEPSChange: 'שינוי רווח למניה 3 שנים',
        tabs: {
            overview: 'כללי',
            news: 'חדשות',
            chart: 'גרף',
            financials: 'פיננסים',
            performance: 'ביצועים',
            insiders: 'בעלי עניין'
        },
    },
    chart: {
        periods: {
            '1d': 'יום',
            '5d': '5י',
            '1m': '1ח',
            '6m': '6ח',
            'q' : 'רבעון',
          'ytd': 'YTD',
            '1y': '1ש',
            '3y': '3ש',
            '5y': '5ש',
        'max' : 'מקס׳'
      },
        ma: 'ממוצעים נעים (MA)',
        indexCompare: 'השוואת מדדים',
        tooltip: {
            change: 'שינוי',
            volume: 'מ',
            open: 'פ',
            close: 'ס',
            high: 'ג',
            low: 'נ',
            price: 'מחיר'
        }
    },
    financials: {
        note: 'הפילטר החודשי מציג שינוי מהרבעון הקודם, בעוד שהפילטר השנתי מציג שינוי מאותו רבעון אשתקד.',
        periodChange: '% שינוי',
        yoy: 'שנתי',
        quarterly: 'רבעוני',
        incomeStatement: 'דו״ח רווח והפסד',
        balanceSheet: 'דו״ח מאזן',
        cashFlowStatement: 'דו״ח תזרים מזומנים',
        revenue: 'הכנסות',
        operatingExpenses: 'הוצאות תפעוליות',
        operatingIncome: 'הכנסות תפעוליות',
        sharesOutstanding: 'מניות בידי הציבור',
        grossIncome: 'רווח גולמי',
        netIncome: 'רווח נקי',
        netProfitMargin: 'שולי רווח נקי',
        eps: 'רווח למניה',
        ebitda: 'רווח תפעולי (EBITDA)',
        cashAndShortTermInvestment: 'מזומן והשקעות לטווח קצר',
        totalAssets: 'סך כל הנכסים',
        totalLiabilities: 'סך כל ההתחייבויות',
        totalEquity: 'סך הון',
        operatingCashFlow: 'מזומנים מפעילות שוטפת',
        netCashFromInvesting: 'מזומן מפעילות השקעה',
        netCashFinancing: 'מזומן מפעילות מימון',
        netChangeInCash: 'שינוי במזומן',
        freeCashFlow: 'מזומנים חופשיים'
    },
    insiders: {
        note: 'עסקאות "בעלי עניין" יכולות לתת סימן  חיובי או שלילי. ' +
            'קניות של בעלי עניין יכולה לאותת על אמון בסיכויים העתידיים של החברה. ' +
            'מכירות של בעלי עניין (כמות משמעותית) יכולה לאותת או להתפרש כאובדן אמון.',
        filingDate: 'תאריך טופס',
        owner: 'שם',
        role: 'תפקיד',
        date: 'תאריך',
        transaction: 'טרנזקציה',
        cost: 'מחיר',
        shares: 'מספר מניות',
        value: 'סכום כולל',
        ownedShares: 'אחזקה',
        ownershipWorth: 'שווי אחזקה כולל',
        ownershipRatio: 'גודל אחזקה יחסי',
        link: 'קישור',
        clickToWatchLink: 'לחצו לצפייה בדיווח',
        reportedAtDate: 'דווח בתאריך',
        totalBuyTransactions: 'מספר עסקאות קניה',
        totalSellTransactions: 'מספר עסקאות מכירה',
        percentOfTotal: 'אחוז מסה״כ',
        transactions: {
            sell: 'מכירה',
            buy: 'קניה',
            option: 'מימוש אופציות',
            award: 'בונוס',
            gift: 'מתנה',
            tax: 'תשלום מס/מימוש'
        }
    },
    trendingStocks: {
        symbol: 'סימול',
        name: 'שם',
        price: 'מחיר',
        sectorIndustry: 'תעשיה',
        ratingChange: 'שינוי בדירוג',
        numberOfMentions: 'מספר אזכורים',
        priceChange: 'שינוי % מחיר',
        toTheFullList: 'לרשימת המלאה'
    },
    dailyBrief: {
        title: 'כדאי לדעת',
        explanation: 'ריכוז מידע תוך יומי וטרום-מסחר שכדאי לדעת',
        indices: {
            title: 'מדדים מובילים',
            usa: 'ארה״ב',
            europe: 'אירופה',
            commodities: 'סחורות',
            asia: 'אסיה',
            crypto: 'קריפטו'
        },
        selectedEarnings: {
            weeklyEarningsTitle: 'דו״חות נבחרים השבוע',
            dailyEarningsTitle: 'דו״חות נבחרים היום',
            seeAllEarnings: 'לחץ לצפייה בכל הדו״חות',
            symbol: 'סימול',
            reportTime: 'זמן דיווח'
        },
        selectedEvents: {
            title: 'אירועים נבחרים היום',
            name: 'שם',
            datetime: 'תאריך ושעה',
            prev: 'נתון קודם',
            est: 'נתון מוערך',
            weeklyEventsTitle: 'אירועים נבחרים השבוע',
            dailyEventsTitle: 'אירועים נבחרים היום',
            noEvents: 'אין אירועים להצגה',
            date: 'תאריך',
            time: 'שעה'
        }
    },
    topTen: {
        proTitle: 'ביצועי שוק',
        title: '10 המניות המובילות',
        gainers: 'העולות',
        losers: 'היורדות',
        active: 'הפעילות',
        nasdaq: 'Nasdaq',
        sp: 'S&P500',
        market: 'כל השוק',
        microCap: 'Micro-Cap ( < $250M)',
        smallCap: 'Small-Cap ($250M - $2B)',
        midCap: 'Mid-Cap ($2B - $10B)',
        largeCap: 'Large-Cap ($10B - $200B)',
        megaCap: 'Mega-Cap ( > $200B)',
        sectors:
            {
                'Communication Services': 'שירותי תקשורת',
                'Consumer Cyclical': 'מוצרי צריכה מחזוריים',
                'Energy': 'אנרגיה',
                'Financial Services': 'שירותים פיננסיים',
                'Real Estate': 'נדל"ן',
                'Technology': 'טכנולוגיה',
                'Basic Materials': 'חומרי גלם',
                'Industrials': 'תעשיות',
                'Utilities': 'תשתיות',
                'Consumer Defensive': 'מוצרי צריכה הגנתיים',
                'Healthcare': 'בריאות'
            }

    },
    sectors: {
        title: 'ביצועי סקטורים',
        marketValue: 'שווי',
        sharesNumber: 'מספר מניות',
        sectorRatio: '% מהסקטור',
        changeP: '% שינוי',
        volume: 'מחזור',
        marketCap: 'שווי שוק',
        pe: 'P/E',
        performance: 'ביצועים לאורך זמן',
        daily: 'שינוי % יומי אחרון'
    },
    calendars: {
        noData: 'לא נמצאו נתונים',
        selection: {
            earnings: 'דו״חות',
            splits: 'ספליטים',
            ipos: 'הנפקות',
            dividends: 'דיבידנדים'
        },
        headers: {
            symbol: 'סימול',
            reportTime: 'זמן דיווח',
            eps: 'EPS',
            dividend: 'דיבידנד',
            exDate: 'תאריך-Ex',
            payableDate: 'תאריך תשלום',
            ratio: 'יחס',
            sharesOffered: 'מספר מניות',
            ipoPrice: 'מחיר הנפקה',
            sharesValue: 'שווי מניות',
            exchange: 'בורסה'
        },
        dates: {
            lastWeek: 'שבוע שעבר',
            past7Days: '7 הימים האחרונים',
            yesterday: 'אתמול',
            today: 'היום',
            tomorrow: 'מחר',
            thisWeek: 'השבוע',
            next7Days: '7 הימים הבאים',
            nextWeek: 'שבוע הבא',
            thisMonth: 'החודש',
            nextMonth: 'חודש הבא'
        },
        labels: {
            all: 'הכל',
            myWatchlistOnly: 'מרשימת המעקב בלבד',
            preMarket: 'לפני מסחר',
            afterMarket: 'אחרי מסחר'
        }
    },
    dividends: {
        symbol: 'סימול',
        name: 'שם',
        lastDivAmount: 'סכום דיב. אחרון',
        lastDivPayoutDate: 'תאריך דיב. אחרון',
        lastDivExDate: 'תאריך Ex אחרון',
        nextDivAmount: 'סכום דיב. הבא',
        nextDivPayoutDate: 'תאריך תשלום הבא',
        nextDivExDate: 'תאריך Ex הבא',
        consecutiveYears: 'שנות תשלום רצופות',
        consecutiveYearsGrowth: 'שנות צמיחה רצופות',
        marketCap: 'שווי שוק',
        industry: 'תעשיה',
        sector: 'סקטור',
        oneYearGrowth: '% צמיחה בגובה הדיבידנד 1ש',
        threeYearGrowth: '% צמיחה בגובה הדיבידנד 3ש',
        fiveYearGrowth: '% צמיחה בגובה הדיבידנד 5ש',
        tenYearGrowth: '% צמיחה בגובה הדיבידנד 10ש',
        payoutRatio: 'שיעור דיבידנד מתוך הרווח',
        payoutFrequency: 'תדירות תשלום',
        yield: 'תשואה'
    }
}
