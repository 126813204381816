<template>
  <div class="notification" :class="{rowNotification: isRow, newsNotification: type === 'news'}">

    <div class="events">
    </div>

    <div class="symbols"
         v-if="notification.symbols.length > 0 && !isRow && (type !== 'news' || notification.tags.includes('price target'))">


      <div
          v-for="(symbol, sIdx) in symbols.slice(0, !showMore ? (feedType === 'symbolPage' ? 1 : 2) : notification.symbols.length)"
          :class="{inWatchlist: symbol.inWatchlist}">
        <SymbolLabel :symbol="symbol" :show-pre-post="true" :pre-market-quote="prices?.preMarketPrices?.[symbol.symbol]"
                     :post-market-quote="prices?.postMarketPrices?.[symbol.symbol]" :prices="prices"/>
      </div>
      <div v-if="notification.symbols.length > (feedType === 'symbolPage' ? 1 : 2)" class="moreSymbols"
           @click="showMore = !showMore">

        <span v-if="!showMore">
          <i class="ri-eye-line"/> {{
            notification.symbols.length - (feedType === 'symbolPage' ? 1 : 2)
          }} {{ dictionary.tags.moreSymbols }}
        </span>
        <span v-else>
          <i class="ri-eye-off-line"/>
        {{ dictionary.tags.hideSymbols }}
        </span>

      </div>
    </div>

    <div class="notificationMessage"
         :class="{breakingNews: notification.isBreaking || notification.isbreaking || notification.tags.includes('highlight')}">

      <a :href="notification.link"
         v-if="!notification.isInner && notification.link && notification.link.includes('http')" target="_blank"/>
      <router-link v-else-if="notification.isInner && !notification.metaData?.isTrade"
                   :to="`/read${notification.link}`"/>
      <router-link v-else-if="notification.metaData?.isTrade" :to="`/trade/${notification.metaData.tradeId}`"/>
      <p :style="shouldMarginBottom()" :class="{textRTL: notificationLang === 'he'}">

        <span class="classTag" :dir="uiDirection"
              v-if="notification.sourceType === 'pr' && notification.sourceName !== 'SEC'">
        <i class="ri-megaphone-line"/>  {{ dictionary.tags.pressRelease }}
        </span>

        <span class="classTag" :dir="uiDirection"
              v-if="notification.tags.includes('insiders') && notification.tags.includes('form-4')">
        <router-link v-if="notification.symbols[0]" :to="`/symbol/${notification.symbols[0].symbol}?tab=insiders`"/>
        <i class="ri-briefcase-4-line"/>  {{ dictionary.tags.insiders }}
        </span>

        <span class="classTag" :dir="uiDirection" v-if="notification.tags.includes('trading halt')">
        <router-link v-if="notification.symbols[0]" :to="`/symbol/${notification.symbols[0].symbol}`"/>
        <i class="ri-stop-circle-line"/>  {{ dictionary.tags.tradingHalt }}
        </span>

        <span class="classTag" :dir="uiDirection" v-if="notification.tags.includes('trading resumption')">
        <router-link v-if="notification.symbols[0]" :to="`/symbol/${notification.symbols[0].symbol}`"/>
        <i class="ri-play-circle-line"/>  {{ dictionary.tags.tradingResumption }}
        </span>

        <span class="classTag" :dir="uiDirection" v-if="notification.tags.includes('trade')">
        <router-link v-if="notification.symbols[0]" :to="`/symbol/${notification.symbols[0].symbol}`"/>
        <i class="ri-line-chart-line"/>  {{ dictionary.tags.trade }}
        </span>


        <span class="classTag" :dir="uiDirection" v-if="notification.tags.includes('signal')">
        <router-link v-if="notification.symbols[0]" :to="`/symbol/${notification.symbols[0].symbol}`"/>
        <i class="ri-stock-line"/>  {{ dictionary.tags.signal }}
        </span>

        {{ text }}
        <span class="moreSymbols fullText" @click="showFullText = !showFullText" v-if="numberOfWords > wordsLimit">
          {{ showFullText ? dictionary.buttons.readLess : dictionary.buttons.readMore }}
        </span>
      </p>
    </div>

    <image-thumbnail v-if="notification.file?.type === 'image'" :border="true"
                     :base64="notification.file.mime === 'base64' ? notification.file.src : null"
                     :image-name="notification.file.name"/>


    <div class="followup" v-if="notification.metaData?.isFollowup">

      <div class="linkWrapper">
        <router-link :to="`/trade/${notification.metaData.tradeId}`"/>
        <p class="followupLabel"><i class="ri-refresh-line"/> {{ dictionary.messages.followup }}
          (<span :class="{up: priceChange > 0, down: priceChange < 0}"
                 class="changeLabel">{{ priceChange }}% {{ dictionary.trades.since }}</span>):</p>
        <p class="followupOriginal" :class="{textRTL: notificationLang === 'he'}">{{ originalTradeText }}</p>
      </div>
      <image-thumbnail v-if="notification.metaData.originalTrade.file?.type === 'image'" :border="true"
                       :base64="notification.metaData.originalTrade.file.mime === 'base64' ? notification.metaData.originalTrade.file.src : null"
                       :image-name="notification.metaData.originalTrade.file.name"/>

      <small>{{ dictionary.trades.published }} @ {{
          formatDateTime(notification.metaData.originalTrade.datetime)
        }}</small>
    </div>

    <NotificationDetails @translate="originalLanguage = !originalLanguage" :feed-type="type"
                         :originalLanguage="originalLanguage"
                         :isRow="isRow" :notification="notification"/>

    <div v-if="notification.metaData && notification.metaData.bullets && notification.tags.includes('form-4')"
         class="bullets">

      <div class="shouldKnow" @click="showShouldKnow = !showShouldKnow; toggleShouldKnow()">

        <div>
          <i class="ri-lightbulb-line"/>
          {{ dictionary.tags.shouldKnowNotificationTitle }} (BETA)
        </div>

        <div class="shouldKnowToggle" v-if="!showShouldKnow">
          {{ dictionary.buttons.showMoreInfo }}
          <i class="ri-arrow-down-s-line"/>
        </div>

        <div class="shouldKnowToggle" v-if="showShouldKnow">
          {{ dictionary.buttons.hideMoreInfo }}
          <i class="ri-arrow-up-s-line"/>
        </div>
      </div>

      <div class="shouldKnowWrapper" ref="shouldKnowWrapper">
        <div class="bullet" v-for="(bullet, bIdx) in notification.metaData.bullets">
          <span style="font-size: 20px; margin-top: -4px;">&bull;</span> {{ bullet?.text[systemLanguage] }}
          <!--        <i class="ri-check-line"/> {{bullet?.text[systemLanguage]}}-->
        </div>
      </div>
    </div>

    <div class="notificationTags" v-if="notification.events.length > 0 && !this.isRow && this.type !== 'news'">

      <div v-for="(symbol, sIdx) in events">
        <div class="eventRow">

          <div v-for="(event, eIndx) in symbol.events" class="tag event"
               :class="{past: event.when && event.when === 'yesterday'}">
            <i class="ri-calendar-line"/>
            {{ notification.symbols.length > 1 ? symbol.symbol : '' }} {{ event.label }}{{ extraEventData(event) }}
            <div v-if="event.dividendRate" class="eventInfo">
              <i class="ri-question-mark" @click="divToolTipOn = !divToolTipOn"/>
              <div class="tooltipExplanation" v-if="divToolTipOn">

                <i class="ri-close-line" @click="divToolTipOn = false"/>
                {{ dictionary.messages.exDiv }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="events.length > 2" class="moreSymbols" @click="showMoreEvents = !showMoreEvents">

        <span v-if="!showMoreEvents">
          <i class="ri-eye-line"/> {{ events.length - 2 }} {{ dictionary.tags.moreEvents }}
        </span>
        <span v-else>
          <i class="ri-eye-off-line"/>
        {{ dictionary.tags.hideSymbols }}
        </span>
      </div>

    </div>


  </div>
</template>

<script>

import {useEquityPrices} from "../../../stores/equityPrices";
import SymbolLabel from "../../symbols/symbol";
import NotificationDetails from "./NotificationDetails";


import extraEventData from "../../../composables/eventTagExtraData";
import ImageOverlay from "../../ui/ImageOverlay";
import ImageThumbnail from "../../ui/imageThumbnail";
import {DateTime} from "luxon";

export default {
  name: "Notification",
  components: {ImageThumbnail, ImageOverlay, NotificationDetails, SymbolLabel},
  props: ['notification', 'isRow', 'type', 'feedType', 'hideEventsBySymbols'],
  setup() {


    const prices = useEquityPrices();
    return {prices, extraEventData};
  },
  created() {

    this.numberOfWords = this.notification.title ? this.notification.title?.split(/[ ]/g).length : 10;
  },
  data() {
    return {
      showImage: false,
      showShouldKnow: false,
      notificationLang: this.systemLanguage,
      divToolTipOn: false,
      originalLanguage: false,
      showMore: false,
      showMoreEvents: false,
      wordsLimit: 30,
      showFullText: false,
      numberOfWords: 1
    }
  },
  computed: {
    priceChange() {

      if (this.notification.tags.includes('followup')) {
        const initPrice = this.notification.metaData?.originalTrade?.priceOnInit ?? null;
        if (initPrice === null) return null;

        const currentPrice = this.prices?.prices[this.notification.symbols[0].symbol]?.price;
        return parseFloat(((currentPrice - initPrice) / initPrice).toFixed(2)) * 100
      } else {
        return null
      }
    },
    symbols() {

      try {
        const watchlist = this.globalWatchlist.watchlist;
        let inWatchlistSymbol = this.notification.symbols.filter(symbol => watchlist.includes(symbol.symbol));
        let notInWatchlistSymbol = this.notification.symbols.filter(symbol => !watchlist.includes(symbol.symbol));

        if (notInWatchlistSymbol.length > 0) {

          inWatchlistSymbol = inWatchlistSymbol.map(symbol => {

            symbol.inWatchlist = true;
            return symbol;
          });
        }

        if (this.feedType === 'symbolPage') {

          let currentSymbol = this.notification.symbols.filter(symbol => symbol.symbol === this.$route.params.symbol);
          let symbolsList = [...currentSymbol, ...inWatchlistSymbol.filter(symbol => symbol.symbol !== this.$route.params.symbol), ...notInWatchlistSymbol.filter(symbol => symbol.symbol !== this.$route.params.symbol)];
          return symbolsList.map(symbol => {

            symbol.inWatchlist = watchlist.includes(symbol.symbol);
            return symbol;
          })
        }
        return [...inWatchlistSymbol, ...notInWatchlistSymbol];
      } catch (e) {
        return this.notification.symbols;
      }
    },
    events() {

      if (this.hideEventsBySymbols) {

        this.notification.events = this.notification.events.filter(event => !this.hideEventsBySymbols.includes(event.symbol))
      }
      return this.notification.events.slice(0, this.showMoreEvents ? this.notification.events.length : 2);
    },
    originalTradeText() {
      let text = null;

      if (!this.notification.metaData?.originalTrade) return '';

      if (!this.originalLanguage) {

        let notificationLanguage = this.systemLanguage === 'en' ? 'en' : 'he';
        this.notificationLang = notificationLanguage;
        text = this.notification.metaData.originalTrade.languages && this.notification.metaData.originalTrade.languages[notificationLanguage] ? this.notification.metaData.originalTrade.languages[notificationLanguage] : this.notification.metaData.originalTrade.title;
      } else {

        let notificationLanguage = this.systemLanguage === 'en' ? 'he' : 'en';
        this.notificationLang = notificationLanguage;
        text = this.notification.metaData.originalTrade.languages && this.notification.metaData.originalTrade.languages[notificationLanguage] ? this.notification.metaData.originalTrade.languages[notificationLanguage] : this.notification.metaData.originalTrade.title;
      }


      return text;
    },
    text() {

      let text = null;

      if (!this.originalLanguage) {

        let notificationLanguage = this.systemLanguage === 'en' ? 'en' : 'he';
        this.notificationLang = notificationLanguage;
        // if (this.systemLanguage === 'en') return this.notification.title;
        text = this.notification.languages && this.notification.languages[notificationLanguage] ? this.notification.languages[notificationLanguage] : this.notification.title;
      } else {

        let notificationLanguage = this.systemLanguage === 'en' ? 'he' : 'en';
        this.notificationLang = notificationLanguage;
        text = this.notification.languages && this.notification.languages[notificationLanguage] ? this.notification.languages[notificationLanguage] : this.notification.title;
      }


      if (text) {
        let splitText = text.split(/[ ]/g);
        return (splitText.length > this.wordsLimit && !this.showFullText) ? splitText.slice(0, this.wordsLimit).join(' ') + '...' : text;
      } else {
        return text;
      }
    }
  },
  methods: {
    formatDateTime(datetime) {

      return DateTime.fromISO(datetime).setLocale(this.dateFormatLocale).toFormat('DDD HH:mm')
    },
    toggleShouldKnow() {
      if (this.showShouldKnow) {
        this.$refs.shouldKnowWrapper.style.height = `${this.$refs.shouldKnowWrapper.scrollHeight}px`
      } else {
        this.$refs.shouldKnowWrapper.style.height = '0px';
      }
    },
    shouldMarginBottom() {
      return this.isRow ? {marginBottom: 0} : {}
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/vars";
@import "../../../assets/style/notification";

.textRTL {
  direction: rtl;
  width: 100%;
  //unicode-bidi: plaintext;
  text-align: right;
}

.bullets {
  margin-top: 20px;

  .shouldKnowWrapper {
    overflow: hidden;
    height: 0;
    transition: $toggleTransition;
  }

  .shouldKnow {
    cursor: pointer;
    background: var(--lightYellowOpacity);
    color: var(--lightYellow);
    padding: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shouldKnowToggle {
      i {
        font-size: 22px;
      }

      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .bullet {

    i {
      margin-top: 2px;
    }

    display: flex;
    align-items: flex-start;
    gap: 5px;
    padding: 5px 0;
    font-size: 14px;
  }
}


</style>
