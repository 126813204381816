<template>
  <section>
    <DataGridTable
        @scrollTopProgress="scrollTopPosition"
        @scrolled="scrolled"
        :data="trades"
        :scrollPosition="scrollPosition"
        :is-compact="true"
        :hovered-columns="true"
        :key="tableKey"
        :default-sort-direction="sortDirection"
        :default-sort="sortKey"
        :height-elements="[]"
        :fixed-col="0"
    >
      <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
        <HeadCell v-for="(th, thIndex) in headers"
                  v-show="th.showCol"
                  :sortKey="sortKey"
                  :blockSort="true"
                  :tooltip="th.tooltip"
                  :key="`th-${thIndex}`"
                  :center="thIndex > 0"
                  :width="th.width"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortKey"
                  @sort="changeSort(th.sortKey)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows"
            :key="`row-${rowIndex}`">


          <td>
            <div class="symbolCell">
              <div class="symbolTd">
                <symbol-link :symbol="row.symbol"/>
                <symbol-logo :medium="true" :symbol="row.symbol"/>
                <symbol-label :key="`s-${row.symbol}`"
                              :dont-link="true"
                              :pre-market-quote="row.tradingData.preMarket"
                              :post-market-quote="row.tradingData.postMarket"
                              :symbol="row.tradingData"
                              :show-prePost="true"/>
              </div>

              <div class="tradeLink">
                <router-link :to='`/trade/${row._id}`'>
                  <i class="ri-line-chart-line"/>
                </router-link>
              </div>
            </div>
          </td>
          <td>
            <div :dir="uiDirection" class="addRemoveSymbol add fixedSixty"
                 @click="addRemoveWatchlistSymbol(row.symbol)"
                 v-if="!watchlist.watchlist.includes(row.symbol)">
              <span>{{ dictionary.buttons.addToPortfolioShort }}</span>
              <i class="ri-folder-add-line"/>
            </div>
            <div :dir="uiDirection" class="addRemoveSymbol remove fixedSixty"
                 @click="addRemoveWatchlistSymbol(row.symbol)"
                 v-if="watchlist.watchlist.includes(row.symbol)">
              <span>{{ dictionary.buttons.removeFromPortfolioShort }}</span>
              <i class="ri-folder-reduce-line"/>
            </div>
          </td>
          <td :dir="uiDirection" class="priceChangeContainer linkable"
              :class="{up: row.changeSincePublished > 0, down: row.changeSincePublished < 0}">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.changeSincePublishedFormat }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ formatDate(row.dateAdded) }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ formatDate(row.lastUpdate) }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.numberOfFollowups }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.tradingData.formattedVolume }}
            <span v-if="row.tradingData.isAboveAvgVolume" class="unusualVolume">{{dictionary.symbolPage.unusualVolume }}</span>
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.tradingData.formattedMarketCap }}
          </td>
          <td :dir="uiDirection" class="priceChangeContainer linkable"
              :class="{up: row.eods[eod] > 0, down: row.eods[eod] < 0}"
              v-for="(eod, eodI) in eods">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.eods[eod] }}%
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.sector }}
          </td>
          <td class="linkable">
            <router-link :to='`/trade/${row._id}`'/>
            {{ row.industry }}
          </td>
        </tr>
      </template>
    </DataGridTable>
  </section>
</template>

<script>
import TradesService from '../services/trades';
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";
import SymbolLabel from "../components/symbols/symbol";
import SymbolLink from "../components/symbols/symbolLink";
import SymbolLogo from "../components/symbols/symbolLogo";

import {DateTime} from "luxon";
import UserService from "../services/user";
import {useWatchlist} from "../stores/watchlist";

export default {
  name: "TradesListView",
  components: {SymbolLogo, SymbolLink, SymbolLabel, HeadCell, DataGridTable},
  data() {
    return {
      tableKey: 0,
      scrollPosition: null,
      trades: [],
      isLoading: true,
      sortDirection: -1,
      sortKey: 'eods.1D',
      watchlist: useWatchlist(),
      isScrollLoading: false,
      eods: ['1D', '5D', '1M', '6M', '1Y'],
      headers: [{
        label: this.dictionary.tables.symbol,
        sortKey: 'symbol',
        key: 'symbol',
        showCol: true
      },{
        label: '',
        showCol: true
      },  {
        label: this.dictionary.trades.percentChange,
        sortKey: 'changeSincePublished',
        tooltip: this.dictionary.trades.percentChangeTooltip,
        showCol: true
      },{
        label: this.dictionary.trades.dateAdded,
        sortKey: 'dateAdded',
        showCol: true
      }, {
        label: this.dictionary.trades.lastUpdate,
        sortKey: 'lastUpdate',
        showCol: true
      }, {
        label: this.dictionary.trades.numberOfFollowups,
        sortKey: 'numberOfFollowups',
        showCol: true
      },   {
        label: this.dictionary.symbolPage.volume,
        sortKey: 'tradingData.volume',
        showCol: true
      }, {
        label: this.dictionary.symbolPage.marketCap,
        sortKey: 'tradingData.marketCap',
        showCol: true
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1d'],
        sortKey: 'eods.1D',
        showCol: true
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['5d'],
        sortKey: 'eods.5D',
        showCol: true
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['1m'],
        sortKey: 'eods.1M',
        showCol: true
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['6m'],
        sortKey: 'eods.6M',
        showCol: true
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.tables['1y'],
        sortKey: 'eods.1Y',
        showCol: true
      }, {
        label: this.dictionary.tables.sector,
        sortKey: 'sector',
        showCol: true
      }, {
        label: this.dictionary.tables.industry,
        sortKey: 'industry',
        showCol: true
      }],
      filter: {},
      page: 0
    }
  },
  created() {

    this.getTradesList().then().catch();

  },
  methods: {
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    formatDate(date) {

      if (!date) return '-';
      return DateTime.fromISO(date).toFormat('DD');
    },
    async getTradesList() {

      this.isLoading = true;
      this.trades = await TradesService.getTradesList({filter: this.filter, page: this.page});
      this.isLoading = false;
    },
    scrolled (position) {
      this.scrollPosition = position;
    },
    // Infinite scroll. The table component informs us when the pages is 80% scrolled to bottom
    scrollTopPosition(value) {

      if (value >= 0.8 /** && some loading bool **/) {

        // this.loadNextPageMethod();
      }
    },
    async changeSort(key) {

      if (key === this.sortKey) {
        this.sortDirection *= -1;
      } else {
        this.sortKey = key;
        this.sortDirection = -1;
      }

      this.tableKey++;

      // this.hasNextPage = true;
      // this.totalPages = 0;
      // this.total = 0;
      // this.page = 0;

      // await this.getDividendsData(true)
    },
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/general";

section {
  min-height: 95vh;
}

.symbolCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
}

.tradeLink {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
  background: var(--mbpGreenOpacity);

  a {
    color: var(--mbpGreen);
    text-decoration: none;
  }
}


.addRemoveSymbol {
  flex: 0 0 55px !important;
  width: 55px;
  margin-left: auto;

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }

  i {
    display: none;
  }

  @media(max-width: 660px) {

    flex: 0 0 30px !important;
    width: 30px;
    height: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    i {
      display: block;
    }

    span {
      display: none;
    }
  }
}

</style>
