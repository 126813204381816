import {createRouter, createWebHistory} from 'vue-router'
import DailyBrief from '../views/DailyBriefView.vue'
import Sectors from '../views/SectorsView.vue'
import Calendars from "../views/CalendarsView";
import WatchlistProView from "../views/WatchlistProView";
import WatchlistView from "../views/WatchlistView";
import SymbolView from "../views/SymbolView";
import SearchView from "../views/SearchView";
import SocialTrend from "../views/SocialTrend";
import IndicesPartial from "../views/dailyBrief/IndicesPartial";
import ReadView from "../views/ReadView";
import FeedView from "../views/FeedView";
import TopTen from "../views/TopTen";
import TopTenPro from "../views/TopTenPro";
import LockView from "../views/LockView";
import SettingsView from "../views/SettingsView";
import TopTrendingView from "../views/TopTrending";
import DividendsView from "../views/DividendsView";
import TradesListView from "../views/TradesListView";
import TradeView from "../views/TradeView";
// import Home from '../views/Home.vue'
// import ShouldKnow from "../views/ShouldKnowView";
// import Heatmap from "../views/HeatmapView";
// import NewUserView from "../views/newUser/NewUserView";
// import InstitutionalView from "../views/Institutional/InstitutionalView";
// import GrowthView from "../views/GrowthView";

const windowWidth = window.innerWidth;

const routes = [
    {
        path: '/',
        name: 'Home',
        components: {
            Side: windowWidth <= 660 ? FeedView : DailyBrief
        },
    },
    // {
    //     path: '/welcome',
    //     name: 'Welcome',
    //     components: {
    //         Side: NewUserView
    //     },
    // },
    {
        path: '/settings',
        name: 'SettingsView',
        components: {
            Side: SettingsView
        },
    },
    {
        path: '/trades',
        name: 'Trades',
        components: {
            Side: TradesListView
        }
    },
    {
        path: '/lock',
        name: 'LockView',
        components: {
            Side: LockView
        },
    }, {
        path: '/toptrending',
        name: 'TopTrending',
        components: {
            Side: TopTrendingView
        },
    },
    // {
    //     path: '/grwt',
    //     name: 'GrowthStocks',
    //     components: {
    //         Side: GrowthView
    //     },
    // },
    // {
    //     path: '/instest',
    //     name: 'Institutional',
    //     components: {
    //         Side: InstitutionalView
    //     },
    // },
    {
        path: '/calendars',
        name: 'Calendars',
        components: {
            Side: Calendars
        },
    },
    {
        path: '/symbolPreview',
        name: 'symbolPreview',
        meta: {
            addHash: true
        }
    },
    {
        path: '/sectors',
        name: 'Sectors',
        components: {
            Side: Sectors
        },
    },
    {
        path: '/top',
        name: 'TopTen',
        components: {
            Side: TopTen
        },
    }, {
        path: '/indices',
        name: 'Indices',
        components: {
            Side: IndicesPartial
        },
    },
    {
        path: '/trade/:tradeId',
        name: 'TradeView',
        components: {
            Side: TradeView
        },
    }, {
        path: '/topro',
        name: 'TopTenPro',
        components: {
            Side: TopTenPro
        },
    },
    {
        path: '/affiliate',
        name: 'Affiliate',
        components: {
            Side: () => import(/* webpackChunkName: "affiliate-view" */ '../views/AffiliateView')
        },
    },
    // {
    //     path: '/heatmap',
    //     name: 'Heatmap',
    //     components: {
    //         Side: Heatmap
    //     },
    // },
    {
        path: '/social',
        name: 'Social',
        components: {
            Side: SocialTrend
        },
    },
    {
        path: '/read/:link',
        name: 'Read',
        components: {
            Side: ReadView
        },
    },
    {
        path: '/devsymbol/:symbol',
        name: 'DevSymbol',
        components: {
            Side: SymbolView
        },
    },
    {
        path: '/symbol/:symbol',
        name: 'Symbol',
        components: {
            Side: SymbolView
        },
    },
    {
        path: '/feed/filtered/:feedId',
        name: 'FilteredFeed',
        components: {
            Side: FeedView
        },
    },
    {
        path: '/feed/sector/:symbol?',
        name: 'SectorFeed',
        components: {
            Side: FeedView
        },
    },
    {
        path: '/feed/:symbol?',
        name: 'Feed',
        components: {
            Side: FeedView
        },
    },
    {
        path: '/macro',
        name: 'Macro',
        components: {
            Side: FeedView
        },
    },
    {
        path: '/dailyBrief',
        name: 'DailyBrief',
        components: {
            Side: DailyBrief
        },
    },
    {
        path: '/search',
        name: 'Search',
        components: {
            Side: SearchView
        },
    },
    {
        path: '/dividends',
        name: 'Dividends',
        components: {
            Side: DividendsView
        },
    },
    {
        path: '/watchlist',
        name: 'MyWatchlist',
        components: {
            Side: WatchlistView
        },
    }, {
        path: '/watchlistPro',
        name: 'MyProWatchlist',
        components: {
            Side: WatchlistProView
        },
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    if (to.name && from.name && to.path === from.path) {
        return;
    }
    next();
});

export default router
